import isDev from "../util/checkDev";
import { Event } from "../util/classed/Event";
import { uploadToStorage } from "../util/firebaseUtil";
import { EventData } from "../util/types";
import { client } from "./api/client";
import { buildALLEventsURL, buildDeleteEventURL, buildEventsURL, buildOwnerCreateEventURL, buildOwnerEditEventURL, buildSingleEventURL } from "./api/urlBuilder";

export async function getClubEvents(clubId: string, token: string) {
    try {
        const response = await client.get(buildEventsURL({ clubId: clubId }), { token });
        return response.data;
    } catch (error) {
        // Handle errors as needed
        console.error('Error fetching club events:', error);
        throw error;
    }
}

export async function getAllEvents(token: string) {
    try {
        const response = await client.get(buildALLEventsURL({ radius: 100, offset: 0, limit: 50 }), { token });
        return response.data;
    } catch (error) {
        // Handle errors as needed
        console.error('Error fetching all events:', error);
        throw error;
    }
}

export async function createEvent(event: EventData, file: File, token: string) {
    const eventObj = new Event(event);
    const json = eventObj.toDict();
    if (isDev()) console.log(json)
    //upload picture to storage if nessecary
    const picURL = await uploadToStorage(file, eventObj.getFileName());
    if (picURL) {
        Object.assign(json, { eventImage: picURL });
        try {
            const response = await client.post(buildOwnerCreateEventURL(), json, { token });
            return response;
        } catch (error) {
            console.error(error)
            throw new Error("Error Creating Event")
        }
    } else {
        throw new Error("Error Uploading File to Storage")
    }
}

export async function editEvent(event: EventData, file: File | null | undefined, token: string) {
    const eventObj = new Event(event);
    const json = eventObj.toDict();
    if (isDev()) console.log(json)
    //upload picture to storage if nessecary
    let picURL: string | null | undefined = null;
    if (file) {
        picURL = await uploadToStorage(file, eventObj.getFileName());
    }
    else {
        picURL = event.eventImage;
    }
    if (picURL) {
        Object.assign(json, { eventImage: picURL });
        try {
            const response = await client.post(buildOwnerEditEventURL(), json, { token });
            return response;
        } catch (error) {
            console.error(error)
            throw new Error("Error Editing Event")
        }
    } else {
        throw new Error("Error Uploading File to Storage")
    }
}

export async function getSingleEvent(eventId: string, token: string) {
    try {
        const response = await client.get(buildSingleEventURL(eventId), { token });
        return response.data;
    } catch (error) {
        // Handle errors as needed
        console.error('Error fetching single event:', error);
        throw error;
    }
}

export async function deleteSingleEvent(eventId: string, token: string) {
    try {
        const response = await client.post(buildDeleteEventURL(), { eventId: eventId }, { token });
        return response.data;
    } catch (error) {
        // Handle errors as needed
        console.error('Error Deleting event:', error);
        throw error;
    }
}