import { client } from "./api/client";
import { buildGuestListCheckInURL, buildGuestListFileURL, buildGuestListURL } from "./api/urlBuilder";

export async function getGuestList(eventId: string, token: string) {
    const response = await client.get(buildGuestListURL({ eventId: eventId }), { token })
    return response.data
}


export async function getGuestListFile(eventId: string, token: string) {
    try {
        const response = await fetch(buildGuestListFileURL({ eventId: eventId }), {
            method: 'GET', // You can change the HTTP method as needed
            headers: {
                // Include the Bearer Token in the request headers
                'Authorization': `Bearer ${token}`,
                // You can add other headers if required
            },
        });

        if (response.ok) {
            const text = await response.text();

            // Create a download link
            const blob = new Blob([text], { type: 'text/plain' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'guestlist.txt'; // Specify the file name
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        } else {
            // Handle the error, e.g., show an error message
            console.error('Failed to download the text file');
        }
    } catch (error) {
        // Handle network errors, e.g., show a network error message
        console.error('Network error:', error);
    }
}


export async function setCheckIn(ticketId: string, checkInStatus: boolean, extraCheckedIn: number | null, token: string) {
    if (extraCheckedIn) {
        const response = await client.post(buildGuestListCheckInURL(), { ticketId: ticketId, checkedIn: checkInStatus, extraCheckedIn: extraCheckedIn }, { token })
        return response.data
    }
    const response = await client.post(buildGuestListCheckInURL(), { ticketId: ticketId, checkedIn: checkInStatus }, { token })
    return response.data
}