import "../../App.css";
import ThreeSteps from "../../components/ownersPromo/LandingPage/3Steps/3Steps";
import FAQ from "../../components/ownersPromo/LandingPage/FAQ/FAQ";
import Features from "../../components/ownersPromo/LandingPage/Features/Features";
import HookTitle from "../../components/ownersPromo/LandingPage/HookTitle/HookTitle";
import Promoter from "../../components/ownersPromo/LandingPage/Promoter/Promoter";

function OwnersPromoPage() {
  return (
    <div className="grid gap-10 px-4 md:px-12 lg:px-24 mt-[74px] md:m-0">
      <div id="home">
        <HookTitle />
      </div>
      <div id="features">
        <Features />
      </div>
      <div id="three-steps">
        <ThreeSteps />
      </div>
      <div id="promoter">
        <Promoter />
      </div>
      <div id="faq">
        <FAQ />
      </div>
    </div>
  );
}

export default OwnersPromoPage;
