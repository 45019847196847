import { createSlice, createAsyncThunk, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../store/store';
import { EventData, Promoter } from '../../util/types';
import { getAllPromoters } from '../../services/promoters';
import { useFirebase } from '../../services/context/authContext';
import { getAuth } from 'firebase/auth';


// Define the initial state
interface AllPromotersState {
    promoters: Promoter[] | null;
    status: 'idle' | 'pending' | 'succeeded' | 'failed';
    error: string | null | undefined;
}

const initialState: AllPromotersState = {
    promoters: null,
    status: 'idle',
    error: null,
};

// Upload Event
export const fetchPromoters = createAsyncThunk('promoters/fetchPromoters', async () => {
    try {
        const auth = getAuth();
        const token = await auth.currentUser?.getIdToken();
        if (!token) {
            throw new Error("Failed to retrieve Token")
        }
        // Upload event to api
        const res = await getAllPromoters(token);
        return res;
    } catch (error) {
        // Handle errors
        throw new Error('Failed fetch club promoters');
    }
});

// Create a slice for events
const promotersSlice = createSlice({
    name: 'promoters',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchPromoters.pending, (state) => {
                state.status = 'pending';
                state.error = null;
            })
            .addCase(fetchPromoters.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.promoters = action.payload.promoters;
            })
            .addCase(fetchPromoters.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

// Exports
export default promotersSlice.reducer;

// Selectors
export const selectPromoters = (state: RootState) => state.promoters.promoters;
export const selectPromotersStatus = (state: RootState) => state.promoters.status;
export const selectPromotersError = (state: RootState) => state.promoters.error;