import { client } from "./api/client";
import { buildAllClubsURL } from "./api/urlBuilder";

export async function getAllClubs(token: string) {
    try {
        const response = await client.get(buildAllClubsURL(), { token });
        return response.data;
    } catch (error) {
        // Handle errors as needed
        console.error('Error fetching clubs:', error);
        throw error;
    }
}