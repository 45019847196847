import '@fontsource/roboto/300.css';
import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React, { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface HeaderProps {
    window?: () => Window;
}

const drawerWidth = 240;
const navItems = [
    { name: 'Home', path: '/' },
    { name: 'Features', path: '#features' },
    { name: 'Ablauf', path: '#three-steps' },
    { name: 'Promoter', path: '#promoter' },
    { name: 'FAQ', path: '#faq' },
    { name: 'Botschafter', path: '/ambassador' },
    { name: 'Datenschutz', path: '/policy' },
    { name: 'Login', path: '/login' }
];

export const Header: React.FC<HeaderProps> = ({ window }) => {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const currentPath = location.pathname;

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const handleNavigation = (path: string) => {
        if (currentPath === '/' && path === '/') {
            const element = document.querySelector("#home");
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
            return;
        }
        if (path.startsWith('#')) {
            const element = document.querySelector(path);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        } else {
            navigate(path);
        }
    };

    const filteredNavItems = useMemo(() => {
        return navItems.filter(item => {
            if (currentPath === '/') {
                return true; // Show all items when on the home page
            } else {
                // Only include items where path starts with '/'
                return item.path.startsWith('/');
            }
        });
    }, [currentPath]);

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ my: 2 }}>
                Baila
            </Typography>
            <Divider />
            <List>
                {filteredNavItems.map((item, index) => (
                    <ListItem key={index} disablePadding>
                        <ListItemButton sx={{ textAlign: 'center' }} onClick={() => handleNavigation(item.path)}>
                            <ListItemText color='white' primary={item.name} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <React.Fragment>
            <AppBar component="nav" style={{ background: 'transparent', boxShadow: 'none' }}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                    >
                        Baila
                    </Typography>
                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                        {filteredNavItems.map((item, index) => (
                            <Button key={index} sx={{ color: 'white' }} onClick={() => handleNavigation(item.path)}>
                                {item.name}
                            </Button>
                        ))}
                    </Box>
                </Toolbar>
            </AppBar>
            <nav>
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </nav>
        </React.Fragment>
    );
};
