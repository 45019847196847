import { Toolbar } from '@mui/material';
import { useEffect } from 'react';

const RedirectPage = () => {
    useEffect(() => {
        // Parse the URL to get the eventId and refBy
        const params = new URLSearchParams(window.location.search);
        const eventId = params.get('eventId');
        const refBy = params.get('refBy');

        // Construct the base custom URL scheme
        let redirectUrl = 'baila://app/event';

        // Add eventId to the URL if it exists
        if (eventId) {
            redirectUrl += `?eventId=${eventId}`;
        }

        // Add refBy to the URL if it exists, maintaining the query string format
        if (refBy) {
            redirectUrl += eventId ? `&refBy=${refBy}` : `?refBy=${refBy}`;
        }

        // Redirect to the constructed URL
        window.location.href = redirectUrl;
    }, []);

    return (
        <div>
            <Toolbar />
            <h1>Redirecting to your Event 🥳...</h1>
            <p>If you are not redirected automatically, <a href="baila://app/event">click here</a>.</p>
        </div>
    );
};

export default RedirectPage;
