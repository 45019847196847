import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ConfirmationDialog from '../general/ConfirmationDialog';
import { AppDispatch } from '../../redux/store/store';
import { useDispatch, useSelector } from 'react-redux';
import { deleteEvent, selectDeleteEventStatus } from '../../redux/slices/editEventSlice';
import { fetchEvents } from '../../redux/slices/eventsSlice';
import { selectUser } from '../../redux/slices/authSlice';
import { EventData } from '../../util/types';
import { parseDateFromString } from '../../util/utils';

interface MediaCardType {
  event: EventData;
  clubId: string;
  setEventSelected: (t: string) => void;
}

export default function EventCard({
  event,
  clubId,
  setEventSelected,
}: MediaCardType) {
  const dispatch: AppDispatch = useDispatch();
  const reqStatus = useSelector(selectDeleteEventStatus);
  const [openDialog, setOpenDialog] = useState(false);

  const handleDeleteEvent = () => {
    // Your delete event logic here
    dispatch(deleteEvent({
      eventId: event.eventId ?? "", clubId: clubId,
      callback: () => { dispatch(fetchEvents({ clubId: clubId ?? "" })) }
    }))
    console.log('Event deleted');
    setOpenDialog(false);
  };

  return (
    <Card sx={{ margin: 4 }}>
      <CardMedia sx={{ height: 140 }} image={event.eventImage} />
      <CardContent>
        <Typography noWrap gutterBottom variant="h5" component="div">
          {event.eventName}
        </Typography>
        <Typography variant="body1" color="text.secondary">
          {parseDateFromString(event.startTime)}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          startIcon={<EditIcon />}
          onClick={() => setEventSelected(event.eventId ?? "")}
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          Bearbeiten
        </Button>
        <Button
          size="small"
          startIcon={<DeleteIcon />}
          onClick={() => setOpenDialog(true)} // Open confirmation dialog on click
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          Löschen
        </Button>
      </CardActions>
      <ConfirmationDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onConfirm={handleDeleteEvent}
        title="Confirmation"
        message="Willst du dieses Event wirklich löschen?"
      />
    </Card>
  );
}
