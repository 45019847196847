import { fetchEvent } from './editEventSlice';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { EventData } from '../../util/types';
import { getClubEvents } from '../../services/events';
import { RootState } from '../store/store';
import { useFirebase } from '../../services/context/authContext';
import { getAuth } from 'firebase/auth';


// Define the initial state
interface ClubEventsState {
    eventList: EventData[] | null;
    status: 'idle' | 'pending' | 'succeeded' | 'failed';
    error: string | null | undefined;
}

// Define initial state
const initialState: ClubEventsState = {
    eventList: null,
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
};

export const fetchClubEvents = createAsyncThunk('clubEvents/fetchClubEvents', async ({ clubId }: { clubId: string }) => {
    try {
        const auth = getAuth();
        const token = await auth.currentUser?.getIdToken();
        if (!token) {
            throw new Error("Failed to retrieve Token")
        }
        // Upload event to api
        const res = await getClubEvents(clubId, token);
        return res;
    } catch (error) {
        // Handle errors
        throw new Error('Failed to fetch Club events');
    }
});

// Create slice
const clubEventsSlice = createSlice({
    name: 'clubEvents',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchClubEvents.pending, (state) => {
                state.status = 'pending';
            })
            .addCase(fetchClubEvents.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.eventList = action.payload.events;
            })
            .addCase(fetchClubEvents.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export default clubEventsSlice.reducer;

// Selectors
export const selectClubEventList = (state: RootState) => state.clubEvents.eventList;
export const selectClubEventsStatus = (state: RootState) => state.clubEvents.status;
export const selectClubEventsError = (state: RootState) => state.clubEvents.error;
