import { Box, Button, CircularProgress, Modal, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { sendOwnerContactMsg } from "../../services/user";

// Modal styling
const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: 4,
    boxShadow: 24,
    p: 4,
};

export default function ContactModal({ open, handleClose }) {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: "",
    });
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setSuccess(false);
        try {
            await sendOwnerContactMsg(formData.email, formData.name, formData.message, "NO-TOKEN");
            setSuccess(true);
            setFormData({ name: "", email: "", message: "" }); // Clear form data
        } catch (error) {
            console.error("Error sending message:", error);
            setSuccess(false);
        } finally {
            setLoading(false);
        }
    };

    const handleCloseModal = () => {
        handleClose(); // Close the modal
        setFormData({ name: "", email: "", message: "" }); // Reset form data
        setSuccess(false); // Reset success state
    };

    return (
        <Modal open={open} onClose={handleCloseModal}>
            <Box sx={modalStyle}>
                <Typography variant="h6" component="h2">
                    Kontaktiere uns und wir melden uns innerhalb von 24h bei dir:
                </Typography>
                {success ? (
                    <Box mt={2} textAlign="center">
                        <Typography variant="body1" color="success.main">
                            Deine Nachricht wurde erfolgreich gesendet!
                        </Typography>
                        <Button variant="outlined" color="secondary" onClick={handleCloseModal} sx={{ mt: 2 }}>
                            Schließen
                        </Button>
                    </Box>
                ) : (
                    <form onSubmit={handleSubmit}>
                        <TextField
                            label="Name"
                            name="name"
                            fullWidth
                            margin="normal"
                            value={formData.name}
                            onChange={handleChange}
                            disabled={loading}
                        />
                        <TextField
                            label="Email"
                            name="email"
                            type="email"
                            fullWidth
                            margin="normal"
                            value={formData.email}
                            onChange={handleChange}
                            disabled={loading}
                        />
                        <TextField
                            label="Nachricht"
                            name="message"
                            fullWidth
                            multiline
                            rows={4}
                            margin="normal"
                            value={formData.message}
                            onChange={handleChange}
                            disabled={loading}
                        />
                        <Box mt={2} display="flex" justifyContent="flex-end">
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={handleCloseModal}
                                disabled={loading}
                                sx={{ mr: 2 }}
                            >
                                Abbrechen
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={loading}
                            >
                                {loading ? <CircularProgress size={24} /> : "Senden"}
                            </Button>
                        </Box>
                    </form>
                )}
            </Box>
        </Modal>
    );
}
