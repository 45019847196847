import '@fontsource/roboto/300.css';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { AppStoreButton } from "react-mobile-app-button";
import appFrame from "../../assets/phone.png";

interface Props {
    window?: () => Window;
}

export default function WelcomePage(props: Props) {
    const { window } = props;
    const iOSUrl = 'https://apps.apple.com/de/app/baila/id6462697936';

    return (
        <Box sx={{
            display: 'flex',
            height: "100vh",
            backgroundColor: "#060809",
        }}>
            <CssBaseline />
            <Box component="main" sx={{ p: 3 }}>
                <Toolbar />
                <div
                    style={{
                        position: 'absolute', left: '50%', top: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}
                >
                    <Typography
                        variant="h3"
                        component="div"
                        color="white"
                        sx={{ flexGrow: 1, textAlign: "center", marginBottom: 2 }}
                    >
                        baila app.
                    </Typography>
                    <img src={appFrame} width="280px" />
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: 20
                    }} >
                        <AppStoreButton url={iOSUrl} theme="dark" />
                    </div>
                </div>
            </Box>
        </Box >
    );
}
