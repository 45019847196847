import { ArrowBack } from "@mui/icons-material";
import { Alert, Button, Container, Grid, TextField, Typography } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setEventData } from "../../redux/slices/createEventSlice";
import { fetchEvent, resetUploadReqStatus, selectEditEvent, selectEditEventFetchStatus, selectEditEventStatus, uploadEditEvent } from "../../redux/slices/editEventSlice";
import { fetchEvents } from "../../redux/slices/eventsSlice";
import { fetchPromoters, selectPromoters } from "../../redux/slices/promotersSlice";
import { AppDispatch } from "../../redux/store/store";
import { getGeoCodeFromAdress } from "../../util/firebaseUtil";
import { convertEventDataToLocal, getTicketArray } from "../../util/utils";
import LoadingView from "../general/LoadingView";
import InputFileUpload from "./InputFileUpload";
import ScrollableCheckboxList from "./ScrollableCheckBoxList";

export default function MasterEditEventForm({ clubId, eventId, setSelectedEventId }: { clubId: string, eventId: string, setSelectedEventId: (t: string | null) => void }) {
    const dispatch: AppDispatch = useDispatch();
    const reqStatusUpload = useSelector(selectEditEventStatus);
    const reqStatusFetch = useSelector(selectEditEventFetchStatus);
    const event = useSelector(selectEditEvent);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [imgUrl, setimgUrl] = useState<string | null>(null);
    const [validationErrorMsg, setValidationErrorMsg] = useState("");
    const [checked, setChecked] = useState<string[]>([]);
    const promoters = useSelector(selectPromoters);

    const initialEventData = {
        ticketPrice: '',
        endTime: '',
        eventName: '',
        eventLocation: '',
        eventDescription: '',
        eventVenueName: '',
        startTime: '',
        age: '',
        genre: '',
        eventId: ''
    };
    const [localEventData, setLocalEventData] = useState(initialEventData);

    useEffect(() => {
        async function fetchData() {
            if (eventId) {
                //get selected event
                dispatch(fetchEvent({ eventId: eventId }))
                dispatch(fetchPromoters())
            }
        }
        fetchData();
    }, [eventId])

    useEffect(() => {
        async function fetchData() {
            if (event) {
                const eventDataLoaded = await convertEventDataToLocal(event);
                setimgUrl(event.eventImage ?? null);
                setLocalEventData(eventDataLoaded);
            }
        }
        fetchData();
    }, [event])

    useEffect(() => {
        if (promoters) {
            const filteredPromoters = promoters?.filter(promoter => promoter?.events?.includes(eventId ?? "")).map(x => x.uid) ?? [];
            setChecked(filteredPromoters);
        }
    }, [promoters])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setLocalEventData({ ...localEventData, [name]: value });
    };

    const handleDateTimeChange = (date: Dayjs | null, fieldName: string) => {
        // Convert the date to a string
        const dateString = date ? date.toISOString() : '';

        // Update the state with the string date
        setLocalEventData({ ...localEventData, [fieldName]: dateString });
    };


    async function handleSubmit() {
        setValidationErrorMsg("");
        if (await validateInputsAndShowError()) {
            const { lat, lng } = await getGeoCodeFromAdress(localEventData.eventLocation);
            if (lat && lng) {
                dispatch(uploadEditEvent({
                    eventData: {
                        ...event,
                        ...localEventData,
                        clubId: clubId ?? "",
                        eventLocation: { latitude: lat, longitude: lng },
                        eventImage: imgUrl ?? "",
                        tickets: localEventData.ticketPrice ? getTicketArray(event?.tickets, parseInt(localEventData.ticketPrice)) : event?.tickets,
                        promoters: checked
                    },
                    file: selectedFile
                }));
                dispatch(setEventData(localEventData));
            } else {
                setValidationErrorMsg("Die angegebene Adresse ist falsch!")
            }
        }
    };

    async function validateInputsAndShowError() {
        const chain = localEventData.startTime &&
            localEventData.endTime &&
            localEventData.eventName &&
            localEventData.eventDescription &&
            localEventData.eventLocation &&
            (selectedFile || imgUrl);
        if (!chain) {
            const errorList: string[] = [];
            if (!localEventData.startTime) {
                errorList.push("Startzeit");
            }
            if (!localEventData.endTime) {
                errorList.push("Endzeit");
            }
            if (!localEventData.eventName) {
                errorList.push("Event Name");
            }
            if (!localEventData.eventDescription) {
                errorList.push("Beschreibung");
            }
            if (!localEventData.eventLocation) {
                errorList.push("Event Adresse");
            }
            if (!selectedFile && !imgUrl) {
                errorList.push("Bilddatei");
            }
            if (errorList.length > 0) {
                const errorMessage = `Die folgenden Felder sind nicht ausgefüllt, werden jedoch benötigt: ${errorList.join(', ')}`;
                setValidationErrorMsg(errorMessage);
                console.error(errorMessage);
            }
        }
        return chain;
    }

    if (reqStatusFetch === "pending" || reqStatusUpload === "pending") {
        return <LoadingView />
    }


    return (
        <Container maxWidth="md" sx={{ marginTop: 3, marginBottom: 10 }}>
            <Button
                variant="contained"
                startIcon={<ArrowBack />}
                sx={{ marginTop: 2, marginBottom: 4 }}
                onClick={() => {
                    //refetch all events (edited events too)
                    dispatch(fetchEvents({ clubId: clubId ?? "" }))
                    setSelectedEventId(null)
                    //remove alert
                    dispatch(resetUploadReqStatus())
                }}
            >
                Back
            </Button>
            {validationErrorMsg && <Alert severity="error">{validationErrorMsg}</Alert>}
            {reqStatusUpload === "succeeded" && <Alert severity="success">{"Event erfolgreich gespeichert!"}</Alert>}
            <Typography variant="h4" gutterBottom>
                Add Event
            </Typography>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <InputFileUpload selectedFile={selectedFile} setSelectedFile={setSelectedFile} url={event?.eventImage} />
                    </Grid>
                    <Grid item xs={6}>
                        <DateTimePicker
                            label="Start"
                            name="startTime"
                            ampm={false}
                            value={localEventData.startTime ? dayjs(localEventData.startTime) : null}
                            onChange={(date) => handleDateTimeChange(date, 'startTime')}
                        //renderInput={(props) => <TextField {...props} />}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DateTimePicker
                            label="Ende"
                            name="endTime"
                            ampm={false}
                            value={localEventData.endTime ? dayjs(localEventData.endTime) : null}
                            onChange={(date) => handleDateTimeChange(date, 'endTime')}
                        //renderInput={(props) => <TextField {...props} />}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            required
                            label="Event Name"
                            name="eventName"
                            value={localEventData.eventName}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Mindestalter"
                            name="age"
                            value={localEventData.age}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Adresse"
                            name="eventLocation"
                            value={localEventData.eventLocation}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Name des Veranstaltungsortes"
                            name="eventVenueName"
                            value={localEventData.eventVenueName}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Genre"
                            name="genre"
                            value={localEventData.genre}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Ticketpreis"
                            name="ticketPrice"
                            value={localEventData.ticketPrice}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Beschreibung"
                            name="eventDescription"
                            value={localEventData.eventDescription}
                            multiline
                            rows={4}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ScrollableCheckboxList
                            clubPromoters={promoters ?? []}
                            setChecked={setChecked}
                            checked={checked} />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Container>
    )
}