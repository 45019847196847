import { BadgeCheck, Instagram } from 'lucide-react';

const Footer = () => {
  return (
    <footer className="w-full bg-customDark text-white py-4">
      <div className="container mx-auto text-center">
        <div className="flex justify-center space-x-6 mb-4"> {/* Reduced margin between social links and copyright */}
          <a
            href="https://www.instagram.com/baila.social"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Instagram"
            className="flex items-center space-x-2"
          >
            <Instagram size={24} />
            <span>baila.social</span>
          </a>
          <a
            href="https://www.tiktok.com/@baila.social"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="TikTok"
            className="flex items-center space-x-2"
          >
            <BadgeCheck size={24} />
            <span>baila.social</span>
          </a>
        </div>
        <p className="text-sm">
          &copy; {new Date().getFullYear()} Baila. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
