import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { getGuestListFile } from '../../services/tickets';
import { useFirebase } from '../../services/context/authContext';

interface MediaCardType {
    eventName: string,
    eventDate: string,
    eventImgUrl: string
    eventId: string,
    setEventSelected: (t: string) => void
}

export default function MediaCard({ eventName, eventDate, eventImgUrl, eventId, setEventSelected }: MediaCardType) {
    const fb = useFirebase();
    return (
        <Card sx={{ margin: 4 }}>
            <CardMedia
                sx={{ height: 140 }}
                image={eventImgUrl}
            />
            <CardContent>
                <Typography noWrap gutterBottom variant="h5" component="div">
                    {eventName}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                    {eventDate}
                </Typography>
            </CardContent>
            <CardActions>
                <Button size="small" onClick={() => setEventSelected(eventId)}>Anzeigen</Button>
                <Button size="small" onClick={async () => {
                    const token = await fb.auth.currentUser?.getIdToken();
                    getGuestListFile(eventId, token ?? "")
                }}>Download</Button>
            </CardActions>
        </Card>
    );
}
