import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Club } from '../../util/types';
import { RootState } from '../store/store';
import { getAuth } from 'firebase/auth';
import { getAllClubs } from '../../services/clubs';


// Define the initial state
interface ClubsState {
    clubList: Club[] | null;
    status: 'idle' | 'pending' | 'succeeded' | 'failed';
    error: string | null | undefined;
}

// Define initial state
const initialState: ClubsState = {
    clubList: null,
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
};

export const fetchClubs = createAsyncThunk('clubs/fetchClubs', async () => {
    try {
        const auth = getAuth();
        const token = await auth.currentUser?.getIdToken();
        if (!token) {
            throw new Error("Failed to retrieve Token")
        }
        // Upload event to api
        const res = await getAllClubs(token);
        return res;
    } catch (error) {
        // Handle errors
        throw new Error('Failed to fetch Clubs');
    }
});

// Create slice
const clubsSlice = createSlice({
    name: 'clubs',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchClubs.pending, (state) => {
                state.status = 'pending';
            })
            .addCase(fetchClubs.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.clubList = action.payload.clubs;
            })
            .addCase(fetchClubs.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export default clubsSlice.reducer;

// Selectors
export const selectClubList = (state: RootState) => state.clubs.clubList;
export const selectClubsStatus = (state: RootState) => state.clubs.status;
export const selectClubsError = (state: RootState) => state.clubs.error;
