import AddIcon from '@mui/icons-material/Add';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LocalBarIcon from '@mui/icons-material/LocalBar';
import PeopleIcon from '@mui/icons-material/People';
import PublicIcon from '@mui/icons-material/Public';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Toolbar from '@mui/material/Toolbar';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ShellToolbar from '../../components/dashboard/ShellToolbar';
import LoadingView from '../../components/general/LoadingView';
import { fetchOwnerUserData, loginFailure, selectAuthLoading, selectUser } from '../../redux/slices/authSlice';
import { selectCurrItem, setCurrItem } from '../../redux/slices/drawerSlice';
import { AppDispatch } from '../../redux/store/store';
import { AppBar, Drawer } from '../../util/styledComponents';
import AllClubEventsPage from './drawer/AllClubEventsPage';
import CreateEvent from './drawer/CreateEventPage';
import DashBoard from './drawer/DashBoard';
import GuestList from './drawer/GuestList';
import NearEventsListPage from './drawer/NearEventsListPage';
import PromotersListPage from './drawer/PromotersListPage';

const slices = ["DASHBOARD", "ALL EVENTS", "ADD EVENT", "GUESTLIST", "ALL PROMOTERS", "NEAR EVENTS"];

export default function OverViewPage() {
  const auth = getAuth();
  const dispatch: AppDispatch = useDispatch();
  const isLoading = useSelector(selectAuthLoading);
  const user = useSelector(selectUser);
  const [open, setOpen] = React.useState(false);
  const currItem = useSelector(selectCurrItem);
  const navigate = useNavigate();
  const toggleDrawer = () => {
    setOpen(!open);
  };

  React.useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const uid = user.uid;
        const token = await user.getIdToken();
        if (uid) {
          dispatch(fetchOwnerUserData({ uid: uid, token: token }));
        } else {
          dispatch(loginFailure(""))
        }
      } else {
        // User is signed out
        dispatch(loginFailure(""));
        navigate("/login")
        console.log("user is logged out")
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  if (isLoading) {
    return <LoadingView />
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="absolute" open={open}>
        <ShellToolbar open={open} setOpen={setOpen} />
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            px: [1],
          }}
        >
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List component="nav">
          {slices.map((item, index) => (
            <ListItemButton
              key={index}
              selected={currItem === item}
              onClick={() => dispatch(setCurrItem(item))}
            >
              <ListItemIcon>
                {index === 0 && <DashboardIcon />}
                {index === 1 && <CalendarMonthIcon />}
                {index === 2 && <AddIcon />}
                {index === 3 && <PeopleIcon />}
                {index === 4 && <PublicIcon />}
                {index === 5 && <LocalBarIcon />}
              </ListItemIcon>
              <ListItemText primary={item} />
            </ListItemButton>
          ))}
        </List>
      </Drawer>
      {currItem === "DASHBOARD" && <DashBoard />}
      {currItem === "ALL EVENTS" && <AllClubEventsPage />}
      {currItem === "ADD EVENT" && <CreateEvent />}
      {currItem === "GUESTLIST" && <GuestList />}
      {currItem === "ALL PROMOTERS" && <PromotersListPage />}
      {currItem === "NEAR EVENTS" && <NearEventsListPage />}
    </Box>
  );
}
