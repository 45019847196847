import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../store/store';
import { EventData } from '../../util/types';
import { createEvent, getClubEvents, getSingleEvent } from '../../services/events';
import { useFirebase } from '../../services/context/authContext';
import { getAuth } from 'firebase/auth';


// Define the initial state
interface EventsState {
    event: EventData | null;
    status: 'idle' | 'pending' | 'succeeded' | 'failed';
    error: string | null | undefined;
}

const initialState: EventsState = {
    event: null,
    status: 'idle',
    error: null,
};

// Upload Event
export const uploadEvent = createAsyncThunk('createEvent/uploadEvent', async ({ eventData, file }: { eventData: EventData, file: File | null | undefined }) => {
    try {
        if (file) {
            const auth = getAuth();
            const token = await auth.currentUser?.getIdToken();
            if (!token) {
                throw new Error("Failed to retrieve Token")
            }
            // Upload event to api
            const res = await createEvent(eventData, file, token);
            return {
                eventId: res?.data?.eventId
            }
        }
    } catch (error) {
        // Handle errors
        throw new Error('Failed to Create Event');
    }
});

// Create a slice for events
const createEventSlice = createSlice({
    name: 'createEvent',
    initialState,
    reducers: {
        setEventData(state, action) {
            state.event = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(uploadEvent.pending, (state) => {
                state.status = 'pending';
                state.error = null;
            })
            .addCase(uploadEvent.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state = initialState;
            })
            .addCase(uploadEvent.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

// Exports
export const { setEventData } = createEventSlice.actions;
export default createEventSlice.reducer;

// Selectors
export const selectEvent = (state: RootState) => state.event;
export const selectCreateEventStatus = (state: RootState) => state.event.status;
export const selectEventError = (state: RootState) => state.event.error;