import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, InputAdornment, ListItemIcon, Switch, TextField, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { AppDispatch } from '../../../redux/store/store';
import { fetchPromoters, selectPromoters, selectPromotersStatus } from '../../../redux/slices/promotersSlice';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { selectUser } from '../../../redux/slices/authSlice';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'; import { getConvertedPromotersRows } from '../../../util/utils';
import LoadingView from '../../../components/general/LoadingView';
import EmailPopup from '../../../components/general/EmailPopUp';

export default function PromotersListPage() {
    const dispatch: AppDispatch = useDispatch();
    const [openEmailPopup, setOpenEmailPopup] = useState(false);
    const [filter, setFilter] = useState('');
    const promotersList = useSelector(selectPromoters);
    const reqStatus = useSelector(selectPromotersStatus);
    const clubId = useSelector(selectUser)?.clubId;
    const promotersListFiltered = useMemo(() => {
        return promotersList?.filter(item =>
            item.name.toLowerCase().includes(filter.toLowerCase()) ||
            item.lastName.toLowerCase().includes(filter.toLowerCase())
        );
    }, [promotersList, filter]);

    useEffect(() => {
        async function fetchData() {
            dispatch(fetchPromoters())
        }
        fetchData();
    }, [])

    const columns: GridColDef[] = [
        {
            field: 'checkedIn',
            headerName: 'Eingecheckt',
            width: 80,
            flex: 0.1,
            renderCell: (params) =>
            (
                params.row.clubId === clubId ? <ListItemIcon><BookmarkIcon /></ListItemIcon> : <></>
            ),
        },
        { field: 'name', headerName: 'Name', width: 130, flex: 0.3 },
        { field: 'lastName', headerName: 'Nachname', width: 130, flex: 0.3 },
        { field: 'gender', headerName: 'Geschlecht', width: 80, flex: 0.3 },
        { field: 'email', headerName: 'E-Mail', width: 130, flex: 0.3 },
    ];

    const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter(e.target.value);
    };



    if (reqStatus === "pending") {
        return <LoadingView />
    }

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flex={1}
            flexDirection="column"
            marginTop={20}
            marginLeft={10}
            marginRight={10}
        >
            <Box sx={{ alignSelf: 'flex-end', marginBottom: 2 }}>
                <Button onClick={() => setOpenEmailPopup(true)} variant="contained" startIcon={<AddIcon />}>
                    Promoter Einladen
                </Button>
            </Box>
            <EmailPopup open={openEmailPopup} onClose={() => setOpenEmailPopup(false)} />

            <Box display="flex"
                justifyContent="space-between"
                width="100%"
                alignItems="center"
                marginBottom={2}
                flexDirection={"row"}>
                <TextField
                    variant="outlined"
                    placeholder='Suchen...'
                    onChange={handleFilterChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
            {promotersListFiltered && promotersListFiltered.length <= 0 ? "Promoterliste leer..." : null}
            {promotersListFiltered && promotersListFiltered.length > 0 ? (
                <DataGrid
                    rows={getConvertedPromotersRows(promotersListFiltered)}
                    sx={{ width: "100%" }}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 5 },
                        },
                    }}
                    pageSizeOptions={[5, 10]}
                />
            ) : null}
        </Box >
    )
}
