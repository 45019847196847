import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store/store';
import { EventData } from '../../util/types';
import { getClubEvents } from '../../services/events';
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";


// Define the initial state
interface EventsState {
    events: EventData[];
    lastFetchCount: number;
    loading: boolean;
    error: string | null | undefined;
}

const initialState: EventsState = {
    events: [],
    lastFetchCount: 0,
    loading: false,
    error: null,
};

// Create an async thunk to fetch events
export const fetchEvents = createAsyncThunk('events/fetchEvents', async ({ clubId }: { clubId: string }) => {
    try {
        const auth = getAuth();
        const token = await auth.currentUser?.getIdToken();
        if (!token) {
            throw new Error("Failed to retrieve Token")
        }
        // Fetch events from your API
        const res = await getClubEvents(clubId, token);
        // Return the events
        return res;
    } catch (error) {
        // Handle errors
        throw new Error('Failed to fetch events');
    }
});

// Create a slice for events
const eventsSlice = createSlice({
    name: 'events',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchEvents.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchEvents.fulfilled, (state, action) => {
                state.loading = false;
                state.events = action.payload.events;
                state.lastFetchCount = action.payload.len;
            })
            .addCase(fetchEvents.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

// Export the reducer
export default eventsSlice.reducer;

// Selectors
export const selectEvents = (state: RootState) => state.events.events;
export const selectEventsLoading = (state: RootState) => state.events.loading;
export const selectEventsError = (state: RootState) => state.events.error;
