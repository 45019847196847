import RefreshIcon from '@mui/icons-material/Refresh';
import { Autocomplete, Box, Button, Grid, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MasterEventCard from '../../../../components/events/master/MasterEventCard';
import MasterEditEventForm from '../../../../components/events/MasterEditEventForm';
import LoadingView from '../../../../components/general/LoadingView';
import { selectUser } from '../../../../redux/slices/authSlice';
import { fetchClubs, selectClubList } from '../../../../redux/slices/clubsSlice';
import { fetchEvents, selectEvents, selectEventsLoading } from '../../../../redux/slices/eventsSlice';
import { AppDispatch } from '../../../../redux/store/store';

export default function MasterEventsListPage() {
    const dispatch: AppDispatch = useDispatch();
    const eventList = useSelector(selectEvents);
    const isLoading = useSelector(selectEventsLoading);
    const user = useSelector(selectUser);
    const clubList = useSelector(selectClubList);
    const [selectedEventId, setSelectedEventId] = useState<string | null>(null);
    const [selectedClubId, setSelectedClubId] = useState<string>("");

    useEffect(() => {
        async function fetchData() {
            dispatch(fetchClubs())
        }
        fetchData();
    }, [])

    useEffect(() => {
        async function fetchData() {
            if (selectedClubId) {
                dispatch(fetchEvents({ clubId: selectedClubId }))
            }
        }
        fetchData();
    }, [selectedClubId])

    if (isLoading) {
        return <LoadingView />
    }


    return (
        <Box
            component="main"
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
                marginTop: 8
            }}
        >
            {!selectedEventId ?
                <>
                    <Typography fontSize={40} variant='h6' sx={{ marginLeft: 4 }} >ALL EVENTS</Typography>
                    <Grid container >
                        <Grid item xs={3} sx={{ marginLeft: 4 }} key={selectedClubId}>
                            <Autocomplete
                                options={clubList || []}
                                getOptionLabel={(option) => option.name || ''}
                                value={clubList?.find(club => club.clubId === selectedClubId) || null}
                                onChange={(event, newValue) => {
                                    setSelectedClubId(newValue?.clubId || '');
                                }}
                                renderInput={(params) => <TextField {...params} label="Clubs" variant="outlined" fullWidth />}
                            />
                        </Grid>

                        <Grid item xs={1} sx={{ alignContent: "center", textAlign: "center" }}>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<RefreshIcon />}
                                onClick={() => {
                                    if (selectedClubId) {
                                        dispatch(fetchEvents({ clubId: selectedClubId }))
                                    }
                                }}
                            >
                                Refresh
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container >
                        {eventList?.map((event, index) => (
                            <Grid item xs={3} key={event.eventId}>
                                <MasterEventCard
                                    setEventSelected={(x) => setSelectedEventId(x)}
                                    event={event}
                                    key={event.eventId}
                                    clubId={selectedClubId}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </>
                : <MasterEditEventForm clubId={selectedClubId} eventId={selectedEventId} setSelectedEventId={setSelectedEventId} />}

        </Box >
    )
}