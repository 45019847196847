import { Star } from "lucide-react";
import { useState } from "react";
import appFrame from "../../../../assets/phone.png";
import ContactModal from "../../../general/ContactModal";

export default function HookTitle() {
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  const handleOpenContactModal = () => setIsContactModalOpen(true);
  const handleCloseContactModal = () => setIsContactModalOpen(false);

  const handleOpenInfoModal = () => setIsInfoModalOpen(true);
  const handleCloseInfoModal = () => setIsInfoModalOpen(false);

  return (
    <div className="flex flex-col md:flex-row items-center mx-auto container h-fit md:h-[100vh]">
      <div className="w-full md:w-[45%] grid gap-4 my-12 md:m-0">
        <h1 className="text-3xl md:text-4xl lg:text-6xl font-bold">
          Das erste Tool für Gästelisten und Events.
        </h1>
        <h1 className="text-base md:text-lg lg:text-xl font-thin italic"></h1>

        <div className="flex gap-4">
          <button
            className="hidden md:flex items-center gap-3 bg-color3 text-black hover:bg-opacity-85 py-3 px-6 rounded-lg w-fit text-lg"
            onClick={handleOpenContactModal}
          >
            <Star size={24} />
            Jetzt Starten
          </button>

          {/* <button
            className="hidden md:flex items-center gap-3 bg-white text-black border border-black hover:bg-opacity-85 py-3 px-6 rounded-lg w-fit text-lg"
            onClick={handleOpenInfoModal}
          >
            <Play size={24} />
            30 Sek. Video anschauen
          </button> */}
        </div>
      </div>

      <div className="w-[15%] hidden md:block" />
      <div className="w-full md:w-[40%] h-full grid md:place-items-center">
        <img src={appFrame} width="280px" />
      </div>

      <button className="flex md:hidden mt-4 md:m-0 items-center gap-2 bg-white text-black hover:bg-opacity-85 py-2 px-8 rounded-lg w-fit">
        Action Btn
      </button>

      <ContactModal open={isContactModalOpen} handleClose={handleCloseContactModal} />
    </div>
  );
}
