import { Typography } from '@mui/material';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { GuestListItem } from '../../util/types';

const SingleTrComp = ({ x }: { x: GuestListItem }) => {
    const checkInStatus = x.checkedIn ? "Ja" : "Nein";
    const newDate = new Date(Date.parse(x.purchasedDate));
    return <Tr>
        <Td>{x.name}</Td>
        <Td>{x.lastName}</Td>
        <Td>{x?.gender ? x.gender : "Unbekannt"}</Td>
        <Td><Typography color={x.checkedIn ? "green" : "blue"}>{checkInStatus}</Typography></Td>
        <Td>{newDate.toLocaleString()}</Td>
    </Tr>
}

interface MobileTableType {
    guestList: GuestListItem[]
}

export default function MobileTable({ guestList }: MobileTableType) {
    return (
        <Table>
            <Thead>
                <Tr>
                    <Th>Name</Th>
                    <Th>Nachname</Th>
                    <Th>Geschlecht</Th>
                    <Th>Eingecheckt</Th>
                    <Th>Eingetragen</Th>
                </Tr>
            </Thead>
            <Tbody>
                {guestList.map((e, i) => <SingleTrComp x={e} key={i} />)}
            </Tbody>
        </Table>
    );
}