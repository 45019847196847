import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store/store';
import { EventData } from '../../util/types';
import { getClubEvents } from '../../services/events';
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { getAllNearEvents } from '../../services/nearEvents';


// Define the initial state
interface EventsState {
    events: EventData[];
    lastFetchCount: number;
    loading: boolean;
    error: string | null | undefined;
}

const initialState: EventsState = {
    events: [],
    lastFetchCount: 0,
    loading: false,
    error: null,
};

// Create an async thunk to fetch events
export const fetchNearEvents = createAsyncThunk('nearEvents/fetchNearEvents', async () => {
    try {
        const auth = getAuth();
        const token = await auth.currentUser?.getIdToken();
        if (!token) {
            throw new Error("Failed to retrieve Token")
        }
        // Fetch near events from your API
        const res = await getAllNearEvents(token);
        // Return the events
        return res;
    } catch (error) {
        // Handle errors
        throw new Error('Failed to fetch near events');
    }
});

// Create a slice for events
const nearEventsSlice = createSlice({
    name: 'nearEvents',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchNearEvents.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchNearEvents.fulfilled, (state, action) => {
                state.loading = false;
                state.events = action.payload.events;
                state.lastFetchCount = action.payload.len;
            })
            .addCase(fetchNearEvents.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

// Export the reducer
export default nearEventsSlice.reducer;

// Selectors
export const selectNearEvents = (state: RootState) => state.nearEvents.events;
export const selectNearEventsLoading = (state: RootState) => state.events.loading;
export const selectNearEventsError = (state: RootState) => state.events.error;
