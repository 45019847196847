import {
  BarChart,
  Bell,
  CircleDollarSign,
  ClipboardList,
  Earth,
  Percent
} from "lucide-react";

const features = [
  {
    icon: <ClipboardList size={24} />,
    title: "Gästeliste",
    explanation:
      "Gästelisten erstellen, Promoter hinzufügen und deine Teilnehmerzahl steigern.",
  },
  {
    icon: <BarChart size={24} />,
    title: "Dashboard",
    explanation:
      "Analysen über Umsätze, Check-Ins (Gästeliste), Frauenqoute und Prognosen für die Zukunft. ",
  },
  {
    icon: <Bell size={24} />,
    title: "Push-Notifications",
    explanation:
      "Bleibe in Kontakt mit deinen Gästen mit Echtzeit-Benachrichtigungen zu Events und Gästelisten.",
  },
  {
    icon: <Earth size={24} />,
    title: "Promoter",
    explanation:
      "Erstelle Events und profitiere von dem Netzwerk aller Promoter der Plattform ",
  },
  {
    icon: <Percent size={24} />,
    title: "Deals & Coupons",
    explanation:
      "Erstelle individuelle Deals und Coupons für deine Gäste, um mehr Engagement zu erzielen.",
  },
  {
    icon: <CircleDollarSign size={24} />,
    title: "Reservierungen",
    explanation:
      "Tischreservierungend direkt über die APP mit dynamischen Preisen.",
  },
];

export default function Features() {
  return (
    <section className="flex flex-col items-center justify-center py-12" style={{ paddingTop: "170px", paddingBottom: "170px" }}>
      <h1 className="text-lg md:text-xl lg:text-3xl font-thin text-center mb-8">
        Wie du in <span className="font-bold">1 Monat</span> mehr <span className="font-bold">Leute</span> für dein Event <span className="font-bold">gewinnst</span>.
      </h1>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 mx-auto max-w-5xl">
        {features.map((item, index) => (
          <div
            key={index}
            className="p-4 rounded-lg border-[.75px] border-gray-400 flex flex-col items-center text-center"
          >
            <div className="mb-4">{item.icon}</div>
            <h1 className="text-lg font-bold mb-2">{item.title}</h1>
            <p className="font-semibold">{item.explanation}</p>
          </div>
        ))}
      </div>
    </section>
  );
}
