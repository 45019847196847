import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, FormControlLabel, FormGroup, IconButton, InputAdornment, Switch, TextField } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useMemo, useState } from 'react';
import { getConvertedRows } from '../../util/utils';
import MobileTable from './MobileTable';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../redux/store/store';
import { fetchGuestlist, selectGuestList, selectGuestListStatus, uploadCheckIn } from '../../redux/slices/guestListSlice';
import LoadingView from '../general/LoadingView';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { GridItemType } from '../../util/types';
import isDev from '../../util/checkDev';
import TextFieldPopUp from '../general/TextFieldPopUp';




interface GuestListDetailViewType {
    eventId: string
    setEventSelected: (t: string) => void
}

export default function GuestListDetailView({ eventId, setEventSelected }: GuestListDetailViewType) {
    const dispatch: AppDispatch = useDispatch();
    const [isMobile, setIsMobile] = useState(false);
    const [filter, setFilter] = useState('');
    const guestList = useSelector(selectGuestList);
    const reqStatus = useSelector(selectGuestListStatus);
    const [openPopup, setOpenPopup] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState<GridItemType | null>(null);
    const guestListFiltered = useMemo(() => {
        return guestList?.filter(item =>
            item.name.toLowerCase().includes(filter.toLowerCase()) ||
            item.lastName.toLowerCase().includes(filter.toLowerCase())
        );
    }, [guestList, filter]);

    useEffect(() => {
        async function fetchData() {
            dispatch(fetchGuestlist({ eventId: eventId }))
        }
        fetchData();
    }, [])

    const handleButtonClick = (rowData: GridItemType, isCheckedIn: boolean) => {
        if (isDev()) console.log('Button clicked for row:', rowData);
        if (rowData.extra && !isCheckedIn) {
            setSelectedRowData(rowData);
            setOpenPopup(true);
        } else {
            performCheckIn(rowData, null);
        }
    };

    const performCheckIn = (rowData: GridItemType, extraCheckedIn: number | null) => {
        dispatch(uploadCheckIn({ eventId: eventId, ticketId: rowData.id, checkInStatus: !rowData.checkedIn, extraCheckedIn: extraCheckedIn }))
        setFilter('');
    }

    const handlePopupSubmit = (value: string) => {
        if (selectedRowData) {
            performCheckIn(selectedRowData, Number(value));
        }
        setOpenPopup(false);
    };

    const columns: GridColDef[] = [
        {
            field: 'checkedIn',
            headerName: 'Eingecheckt',
            width: 80,
            flex: 0.1,
            renderCell: (params) => (
                params.value ? <CheckIcon color="primary" /> : <ClearIcon color="error" />
            ),
        },
        { field: 'name', headerName: 'Name', width: 130, flex: 0.3 },
        { field: 'lastName', headerName: 'Nachname', width: 130, flex: 0.3 },
        { field: 'gender', headerName: 'Geschlecht', width: 80, flex: 0.3 },
        { field: 'purchasedDate', headerName: 'Eingetragen', width: 130, flex: 0.3 },
        { field: 'extra', headerName: 'Begleitung', width: 130, flex: 0.3 },
        {
            field: 'action',
            headerName: 'Aktion',
            sortable: false,
            width: 110,
            renderCell: (params) => (
                <Button variant="contained" color={params.row.checkedIn ? "error" : "primary"} onClick={() => handleButtonClick(params.row, params.row.checkedIn)}>
                    {params.row.checkedIn ? "Check out" : "Check In"}
                </Button>
            ),
        },
    ];

    const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter(e.target.value);
    };


    if (reqStatus === "pending") {
        return <LoadingView />
    }

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flex={1}
            flexDirection="column"
            marginTop={isMobile ? 10 : 20}
            marginLeft={isMobile ? 2 : 10}
            marginRight={isMobile ? 2 : 10}
        >
            <Box sx={{ alignSelf: 'start', marginBottom: 2 }}>
                <IconButton onClick={() => setEventSelected("")}  >
                    <ArrowBackIcon />
                </IconButton>
            </Box>
            <TextFieldPopUp
                title='Wieviele Personen möchtest du einchecken?'
                inputLabel='Anzahl'
                open={openPopup}
                onSubmit={handlePopupSubmit}
                onCancel={() => setOpenPopup(false)}
                onClose={() => setOpenPopup(false)} />
            <Box display="flex"
                justifyContent="space-between"
                width="100%"
                alignItems="center"
                marginBottom={2}
                flexDirection={isMobile ? "column" : "row"}>
                {isMobile ? <Box mb={2}>
                    <FormGroup>
                        <FormControlLabel control={<Switch defaultChecked onChange={() => setIsMobile(!isMobile)} />} label="Mobile View" />
                    </FormGroup>
                </Box> : null}
                <TextField
                    variant="outlined"
                    placeholder='Suchen...'
                    onChange={handleFilterChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                {!isMobile ? <Box>
                    <FormGroup>
                        <FormControlLabel control={<Switch onChange={() => setIsMobile(!isMobile)} />} label="Mobile View" />
                    </FormGroup>
                </Box> : null}
            </Box>
            {guestListFiltered && guestListFiltered.length <= 0 ? "Gästeliste leer..." : null}
            {!isMobile && guestListFiltered && guestListFiltered.length > 0 ? (
                <DataGrid
                    rows={getConvertedRows(guestListFiltered)}
                    sx={{ width: "100%" }}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 5 },
                        },
                    }}
                    pageSizeOptions={[5, 10]}
                />
            ) : null}
            {isMobile && guestListFiltered && guestListFiltered.length > 0 ?
                <MobileTable guestList={guestListFiltered} />
                : null}
        </Box >
    )
}