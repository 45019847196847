import * as React from 'react';
import { List, ListItem, ListItemText, Checkbox, Paper, ListItemIcon } from '@mui/material';
import { Promoter } from '../../util/types';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store/store';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { selectUser } from '../../redux/slices/authSlice';

type ScrollableCheckboxListProps = {
  clubPromoters: Promoter[];
  setChecked: (checked: string[]) => void;
  checked: string[];
};

const ScrollableCheckboxList = ({ clubPromoters, setChecked, checked }: ScrollableCheckboxListProps) => {
  const selectedClubId = useSelector(selectUser)?.clubId;

  // Sort promoters based on whether they have the same clubId
  const sortedPromoters = React.useMemo(() => {
    const sameClubIdPromoters = clubPromoters.filter(promoter => promoter.clubId === selectedClubId);
    const otherPromoters = clubPromoters.filter(promoter => promoter.clubId !== selectedClubId);
    return [...sameClubIdPromoters, ...otherPromoters];
  }, [clubPromoters, selectedClubId]);

  const handleToggle = (promoter: Promoter) => () => {
    const currentIndex = checked.indexOf(promoter.uid);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(promoter.uid);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return (
    <Paper style={{ maxHeight: 300, overflowY: 'auto' }}>
      <List>
        {sortedPromoters.map((promoter) => (
          <ListItem key={promoter.uid} dense button onClick={handleToggle(promoter)}>
            <Checkbox
              edge="start"
              checked={checked.indexOf(promoter.uid) !== -1}
              tabIndex={-1}
              disableRipple
            />
            <ListItemText primary={`Promoter ${promoter.name}`} />
            {promoter.clubId === selectedClubId && <ListItemIcon><BookmarkIcon /></ListItemIcon>}
          </ListItem>
        ))}
      </List>
    </Paper>
  );
};

export default ScrollableCheckboxList;
