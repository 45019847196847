import { AppStoreButton } from "react-mobile-app-button";
import appFrame from "../../../../assets/phone2.png";

export default function Promoter() {
  const iOSUrl = 'https://apps.apple.com/de/app/baila-insights/id6608959535';
  return (
    <div className="flex flex-col md:flex-row items-center mx-auto container h-fit md:h-[100vh]">
      <div className="w-full md:w-[55%] grid gap-4 md:gap-8 lg:gap-4 my-12 md:m-0">
        <h1 className="text-3xl md:text-4xl lg:text-6xl font-bold">Promoter werden.</h1>
        <p className="pl-4 border-l-2 border-white text-gray-300">
          <span className="font-bold">Registriere</span> dich in <span className="font-bold">unserer App</span> und <span className="font-bold">erhalte Geld</span> für <span className="font-bold">jeden Gast</span>, den du mitbringst.<br />
          Falls du schon <span className="font-bold">Promoter</span> bist, kannst du <span className="font-bold">Ref-Links</span> erstellen und <span className="font-bold">Gäste automatisch</span> zu deiner <span className="font-bold">Gästeliste hinzufügen</span>.<br />
          Außerdem kannst du dein <span className="font-bold">voraussichtliches Einkommen</span> und vieles mehr <span className="font-bold">direkt in der App kontrollieren</span>.
        </p>
        <AppStoreButton url={iOSUrl} theme="dark" className="mt-3" /> {/* Added margin-top here */}
      </div>
      <div className="w-[15%] hidden md:block" />
      <div className="w-full md:w-[30%] h-full grid md:place-items-center">
        <img src={appFrame} width="280px" />
      </div>
    </div>
  );
}
