import { useState } from "react";
import appFrame from "../../../../assets/phone2.png";
import ContactModal from "../../../general/ContactModal";


export default function Ambassador() {
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  return (
    <div className="flex flex-col md:flex-row items-center mx-auto container h-fit md:h-[100vh]">
      <div className="w-full md:w-[55%] grid gap-4 md:gap-8 lg:gap-4 my-12 md:m-0">
        <h1 className="text-3xl md:text-4xl lg:text-6xl font-bold">
          Ambassador werden.
        </h1>
        <p className="pl-4 border-l-2 border-white text-gray-300">
          Du bist im <span className="font-bold">Nachtleben</span>, auf <span className="font-bold">Festivals</span> oder im <span className="font-bold">Eventbusiness</span> zuhause?<br />
          Dann suchen wir genau dich als <span className="font-bold">Botschafter</span>.<br />
          Für jeden Kontakt, den du uns vermittelst, beteiligen wir dich zu <span className="font-bold">20% am Gewinn auf Lebenszeit</span>.<br />
          D.h. du verdienst <span className="font-bold">passives Einkommen</span> an jedem verkauften Ticket und erhälst <span className="font-bold">exklusiven Zugang zu Clubs</span>.
        </p>

        <button
          className="hidden md:flex items-center gap-2 bg-color3 text-black hover:bg-opacity-85 py-2 px-8 rounded-lg w-fit"
          onClick={handleOpenModal}
        >
          Jetzt Starten
        </button>
      </div>
      <div className="w-[15%] hidden md:block" />
      <div className="w-full md:w-[30%] h-full grid md:place-items-center">
        <img src={appFrame} width="280px" />
      </div>
      <button
        className="flex md:hidden mt-4 md:m-0 items-center gap-2 bg-white text-black hover:bg-opacity-85 py-2 px-8 rounded-lg w-fit"
        onClick={handleOpenModal}
      >
        Action Btn
      </button>

      <ContactModal open={modalOpen} handleClose={handleCloseModal} />
    </div>
  );
}
