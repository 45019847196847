import { client } from "./api/client";
import { buildNearEventsURL } from "./api/urlBuilder";

export async function getAllNearEvents(token: string) {
    try {
        const response = await client.get(buildNearEventsURL(), { token });
        return response.data;
    } catch (error) {
        // Handle errors as needed
        console.error('Error fetching all near events:', error);
        throw error;
    }
}