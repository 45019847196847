import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Box } from '@mui/material';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const UploadContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
});

export default function InputFileUpload({ setSelectedFile, selectedFile, url }: { setSelectedFile: (x) => any, selectedFile: any, url?: string }) {

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  return (
    <UploadContainer>
      {(selectedFile || url) && (
        <Box
          component="img"
          sx={{
            height: 350,
            maxHeight: { xs: 350, md: 250 },
          }}
          src={selectedFile ? URL.createObjectURL(selectedFile) : url}
        />
      )}
      <Button
        component="label"
        role={undefined}
        variant="contained"
        tabIndex={-1}
        style={{ marginTop: 25 }}
        startIcon={<CloudUploadIcon />}
      >
        Upload file
        <VisuallyHiddenInput type="file" onChange={handleFileChange} />
      </Button>
    </UploadContainer>
  );
}
