import { client } from "./api/client";
import { buildClubPromotersURL as buildPromotersURL } from "./api/urlBuilder";

export async function getAllPromoters(token: string) {
    try {
        const response = await client.get(buildPromotersURL(), { token });
        return response.data;
    } catch (error) {
        // Handle errors as needed
        console.error('Error fetching club promoters:', error);
        throw error;
    }
}