import { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import ConfirmationDialog from '../general/ConfirmationDialog';
import { AppDispatch } from '../../redux/store/store';
import { useDispatch, useSelector } from 'react-redux';
import { deleteEvent, selectDeleteEventStatus } from '../../redux/slices/editEventSlice';
import { fetchEvents } from '../../redux/slices/eventsSlice';
import { selectUser } from '../../redux/slices/authSlice';

interface MediaCardType {
  eventName: string;
  eventDate: string;
  eventImgUrl: string;
  eventId: string;
  setEventSelected: (t: string) => void;
}

export default function NearEventCard({
  eventName,
  eventDate,
  eventImgUrl,
  eventId,
  setEventSelected,
}: MediaCardType) {
  const dispatch: AppDispatch = useDispatch();
  const user = useSelector(selectUser);
  const reqStatus = useSelector(selectDeleteEventStatus);
  const [openDialog, setOpenDialog] = useState(false);

  const handleDeleteEvent = () => {
    // Your delete event logic here
    dispatch(deleteEvent({
      eventId: eventId, clubId: user?.clubId,
      callback: () => { dispatch(fetchEvents({ clubId: user?.clubId ?? "" })) }
    }))
    console.log('Event deleted');
    setOpenDialog(false);
  };

  return (
    <Card sx={{ margin: 4 }}>
      <CardMedia sx={{ height: 140 }} image={eventImgUrl} />
      <CardContent>
        <Typography noWrap gutterBottom variant="h5" component="div">
          {eventName}
        </Typography>
        <Typography variant="body1" color="text.secondary">
          {eventDate}
        </Typography>
      </CardContent>
      <ConfirmationDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onConfirm={handleDeleteEvent}
        title="Confirmation"
        message="Willst du dieses Event wirklich löschen?"
      />
    </Card>
  );
}
