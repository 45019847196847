import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store/store';
import { client } from '../../services/api/client';
import { buildUserOwnerDataURL } from '../../services/api/urlBuilder';

export type OwnerUserData = {
  clubId?: string;
  email: string;
  uid: string;
  userType?: string;
};

interface AuthState {
  user: OwnerUserData | null;
  loading: boolean;
  error: string | null;
}

const initialState: AuthState = {
  user: null,
  loading: false,
  error: null,
};

// Create an async thunk to fetch userdata
export const fetchOwnerUserData = createAsyncThunk('auth/fetchOwnerUserData', async ({ uid, token }: { uid: string, token: string }) => {
  try {
    const res = await client.get(buildUserOwnerDataURL({ userId: uid }), { token })
    return {
      owner: {
        clubId: res.data.owner.clubId,
        email: res.data.owner.email,
        userType: res.data.owner.userType,
        uid: res.data.owner.uid
      }
    }
  } catch (error) {
    // Handle errors
    throw new Error('Failed to fetch UserData');
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginRequest(state) {
      state.loading = true;
      state.error = null;
    },
    loginSuccess(state, action: PayloadAction<OwnerUserData>) {
      state.user = { ...action.payload, clubId: "" };
      state.loading = false;
      state.error = null;
    },
    loginFailure(state, action: PayloadAction<string>) {
      state.user = null;
      state.loading = false;
      state.error = action.payload;
    },
    signOut(state) {
      state.user = null;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOwnerUserData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOwnerUserData.fulfilled, (state, action) => {
        state.loading = false;
        const ownerData = { ...action.payload.owner } as OwnerUserData;
        if (ownerData) {
          state.user = {
            uid: ownerData.uid,
            email: ownerData.email,
            clubId: ownerData.clubId,
            userType: ownerData.userType
          }
        }
      })
      .addCase(fetchOwnerUserData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "";
      });
  },
});

export default authSlice.reducer;

export const { loginRequest, loginSuccess, loginFailure, signOut } = authSlice.actions;

// Selectors
export const selectUser = (state: RootState) => state.auth.user;
export const selectAuthError = (state: RootState) => state.auth.error;
export const selectAuthLoading = (state: RootState) => state.auth.loading;