import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';

interface CustomPopupProps {
    open: boolean;
    onClose: () => void;
    title: string;
    inputLabel: string;
    inputPlaceholder?: string;
    onCancel: () => void;
    onSubmit: (value: string) => void;
}

const TextfieldPopUp: React.FC<CustomPopupProps> = ({ open, onClose, title, inputLabel, inputPlaceholder, onCancel, onSubmit }) => {
    const [inputValue, setInputValue] = useState('');
    const [isValid, setIsValid] = useState(true);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
        validateInput(e.target.value);
    };

    const validateInput = (value: string) => {
        const isNumber = !isNaN(Number(value));
        setIsValid(isNumber);
    };

    const handleSubmit = () => {
        if (isValid) {
            onSubmit(inputValue);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <TextField
                    margin="normal"
                    fullWidth
                    label={inputLabel}
                    placeholder={inputPlaceholder}
                    value={inputValue}
                    onChange={handleInputChange}
                    error={!isValid}
                    helperText={!isValid ? "Please enter a valid number" : ""}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color="primary">
                    Abbrechen
                </Button>
                <Button onClick={handleSubmit} color="primary" disabled={!isValid}>
                    Senden
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default TextfieldPopUp;