import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import GuestListDetailView from '../../../components/guestlist/GuestListDetailView';
import GuestListEventList from '../../../components/guestlist/GuestListEventList';
import { getClubEvents } from '../../../services/events';
import { selectUser } from '../../../redux/slices/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../redux/store/store';
import { fetchClubEvents, selectClubEventList } from '../../../redux/slices/clubEventsSlice';


export default function GuestList() {
    const [selectedEventId, setSelectedEventId] = useState("");
    const dispatch: AppDispatch = useDispatch();
    const user = useSelector(selectUser);

    useEffect(() => {
        async function fetchData() {
            if (user?.clubId) {
                dispatch(fetchClubEvents({ clubId: user?.clubId ?? "" }))
            }
        }
        fetchData();
    }, [user?.clubId])

    return (
        <Box
            component="main"
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
            }}
        >

            {selectedEventId ? <GuestListDetailView setEventSelected={setSelectedEventId} eventId={selectedEventId} /> :
                <GuestListEventList setEventSelected={setSelectedEventId} />}

        </Box >
    )
}