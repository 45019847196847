import { Tooltip, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const InfoTooltip = ({ title }) => (
    <Tooltip title={title} placement="right" arrow>
        <IconButton aria-label="info" style={{ marginLeft: 8 }}>
            <InfoIcon />
        </IconButton>
    </Tooltip>
);

export default InfoTooltip;
