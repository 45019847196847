import { EventData, EventLocation, Ticket } from "../types";

export class Event {
    tickets: Ticket[];
    eventVenueName: string | undefined;
    clubId: string;
    endTime: string;
    eventName: string;
    eventLocation: EventLocation;
    eventDescription: string;
    startTime: string;
    eventImage: string | undefined;
    ticketsTotalAvailable: number | undefined;
    ticketsTotalSold: number | undefined;
    age: number | undefined;
    genre: string | undefined;
    eventId?: string | undefined;
    revenue?: number | undefined;
    views?: number | undefined;
    promoters?: string[] | undefined;
    publicPromote?: boolean | undefined;
    promoterProvision?: number | undefined;

    constructor(data: EventData) {
        this.tickets = data?.tickets ?? [];
        this.eventVenueName = data?.eventVenueName;
        this.clubId = data.clubId;
        this.endTime = data.endTime;
        this.eventName = data.eventName;
        this.eventLocation = data.eventLocation;
        this.eventDescription = data.eventDescription;
        this.startTime = data.startTime;
        this.eventImage = data.eventImage;
        this.ticketsTotalAvailable = data.ticketsTotalAvailable;
        this.ticketsTotalSold = data.ticketsTotalSold;
        this.age = data?.age ? parseInt(data.age) : undefined;
        this.genre = data.genre;
        this.eventId = data?.eventId;
        this.revenue = data?.revenue;
        this.views = data?.views;
        this.promoters = data?.promoters;
        this.publicPromote = data?.publicPromote;
        this.promoterProvision = data?.promoterProvision;
    }

    getFileName(): string {
        return this.eventName + this.startTime;
    }

    toDict(): Record<string, any> {
        const jsonBase: Record<string, any> = {
            startTime: this.startTime,
            endTime: this.endTime,
            eventLocation: this.eventLocation,
            eventName: this.eventName,
            eventDescription: this.eventDescription,
            eventImage: this.eventImage ?? "",
            clubId: this.clubId,
        };

        if (this.tickets && this.tickets?.length > 0) {
            Object.assign(jsonBase, { tickets: this.tickets })
        }
        if (this.eventVenueName) {
            jsonBase.eventVenueName = this.eventVenueName;
        }
        if (this.ticketsTotalAvailable !== undefined) {
            jsonBase.ticketsTotalAvailable = this.ticketsTotalAvailable;
        }
        if (this.ticketsTotalSold !== undefined) {
            jsonBase.ticketsTotalSold = this.ticketsTotalSold;
        }
        if (this.age !== undefined) {
            jsonBase.age = this.age;
        }
        if (this.genre) {
            jsonBase.genre = this.genre;
        }
        if (this.eventId) {
            jsonBase.eventId = this.eventId;
        }
        if (this.revenue !== undefined) {
            jsonBase.revenue = this.revenue;
        }
        if (this.views !== undefined) {
            jsonBase.views = this.views;
        }
        if (this.promoters) {
            jsonBase.promoters = this.promoters;
        }
        if (this.publicPromote !== undefined) {
            jsonBase.publicPromote = this.publicPromote;
        }
        if (this.promoterProvision !== undefined) {
            jsonBase.promoterProvision = this.promoterProvision;
        }

        return jsonBase;
    }
}
