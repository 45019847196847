import { ChevronDown, ChevronRight } from "lucide-react";
import { useState } from "react";
import ContactModal from "../../../general/ContactModal";

export default function FAQ() {
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);

  const handleOpenContactModal = () => setIsContactModalOpen(true);
  const handleCloseContactModal = () => setIsContactModalOpen(false);

  return (
    <div className="w-full min-h-fit flex flex-col gap-4 mx-auto container" style={{ paddingTop: "100px", paddingBottom: "100px" }} >
      <h1 className="text-3xl md:text-4xl lg:text-6xl font-bold text-center">FAQ</h1>
      <div className="grid gap-4 w-full px-6 sm:px-12 md:px-24 lg:px-32 xl:px-52 2xl:px-72">
        {/* FAQ Item 1 */}
        <div
          className="bg-white bg-opacity-15 rounded-lg p-4 w-full duration-500 transition-all ease-in cursor-pointer"
          onClick={() => setSelectedId(selectedId === 1 ? null : 1)}
        >
          <div className="flex items-center gap-4">
            <div>{selectedId === 1 ? <ChevronDown /> : <ChevronRight />}</div>
            <h1 className="font-semibold">Warum das ganze auch für deinen Club ohne Mehraufwand funktioniert</h1>
          </div>
          {selectedId === 1 && (
            <ul className="text-gray-200 list-disc pl-4 mt-2">
              <li>Wir haben dieses Tool auf Basis bestehender Promo-Methoden wie z.b. Gästelisten entwickelt die von den meisten Clubs sowieso schon verwendeten werden.</li>
              <li>Der Vorteil unserer digitalen Gästeliste ist, dass Promoter dein Event verbreiten können und neue Kreise von Gästen mitbringen, welche dann wiederum zum Aufbau einer Zielgruppe verwendet werden können.</li>
            </ul>
          )}
        </div>

        {/* FAQ Item 2 */}
        <div
          className="bg-white bg-opacity-15 rounded-lg p-4 w-full duration-500 transition-all ease-in cursor-pointer"
          onClick={() => setSelectedId(selectedId === 2 ? null : 2)}
        >
          <div className="flex items-center gap-4">
            <div>{selectedId === 2 ? <ChevronDown /> : <ChevronRight />}</div>
            <h1 className="font-semibold">Wie du mehr weibliche Gäste für dein Event bekommst</h1>
          </div>
          {selectedId === 2 && (
            <ul className="text-gray-200 list-disc pl-4 mt-2">
              <li>Durch das Sammeln der Daten kannst du nachverfolgen welche Events und Aktionen besser ankommen und somit Frauen spezielle Angebote machen.</li>
              <li>Der Unterschied zu den einfachen Frauen-Kostenlos-Tickets ist das genaue Tracking und dass durch die Push-Nachricht die Aktion auch gelesen wird.</li>
              <li>Nichts für ungut aber die Öffnungsraten von Mails können mit denen von Direkt-Mitteilungen nicht mithalten.</li>
            </ul>
          )}
        </div>

        {/* FAQ Item 3 */}
        <div
          className="bg-white bg-opacity-15 rounded-lg p-4 w-full duration-500 transition-all ease-in cursor-pointer"
          onClick={() => setSelectedId(selectedId === 3 ? null : 3)}
        >
          <div className="flex items-center gap-4">
            <div>{selectedId === 3 ? <ChevronDown /> : <ChevronRight />}</div>
            <h1 className="font-semibold">Welche Kosten muss ich einplanen?</h1>
          </div>
          {selectedId === 3 && (
            <ul className="text-gray-200 list-disc pl-4 mt-2">
              <li>Der größte Vorteil von baila insights ist, dass alle Tools komplett kostenlos sind.</li>
              <li>Das heißt du kannst heute noch starten und direkt mehr Sichtbarkeit für deine Events schaffen ohne irgendwelche Kosten.</li>
              <li>Sobald du auch Tickets verkaufen möchtest, verlangen wir eine Gebühr von 5% +50 Cent.</li>
              <li>Aber auch hier bezahlst du nur wenn auch Tickets verkauft wurden, ansonsten nicht.</li>
            </ul>
          )}
        </div>

        {/* FAQ Item 4 */}
        <div
          className="bg-white bg-opacity-15 rounded-lg p-4 w-full duration-500 transition-all ease-in cursor-pointer"
          onClick={() => setSelectedId(selectedId === 4 ? null : 4)}
        >
          <div className="flex items-center gap-4">
            <div>{selectedId === 4 ? <ChevronDown /> : <ChevronRight />}</div>
            <h1 className="font-semibold">Wie kann ich ein Event erstellen?</h1>
          </div>
          {selectedId === 4 && (
            <ul className="text-gray-200 list-disc pl-4 mt-2">
              <li>Beantrage einfach deinen Account <a href="#" onClick={handleOpenContactModal} className="text-blue-500 hover:underline">hier</a> und wir melden uns innerhalb von 24h und zeigen dir alles in einem Video-Call.</li>
              <li>Falls du schon einen Account hast, kannst du auch einfach dieses Video schauen ;)</li>
            </ul>
          )}
        </div>

        {/* FAQ Item 5 */}
        <div
          className="bg-white bg-opacity-15 rounded-lg p-4 w-full duration-500 transition-all ease-in cursor-pointer"
          onClick={() => setSelectedId(selectedId === 5 ? null : 5)}
        >
          <div className="flex items-center gap-4">
            <div>{selectedId === 5 ? <ChevronDown /> : <ChevronRight />}</div>
            <h1 className="font-semibold">Was bringt mir die Analyse?</h1>
          </div>
          {selectedId === 5 && (
            <ul className="text-gray-200 list-disc pl-4 mt-2">
              <li>Du kannst die Umsätze, Check-Ins von Gästelisten, Frauenquote und zukünftige Prognosen einsehen.</li>
              <li>Durch Push-Nachrichten kannst du deine Gäste nach Events kontaktieren und deine Events somit zum größten Teil im Voraus ausverkaufen.</li>
            </ul>
          )}
        </div>

        {/* FAQ Item 6 */}
        <div
          className="bg-white bg-opacity-15 rounded-lg p-4 w-full duration-500 transition-all ease-in cursor-pointer"
          onClick={() => setSelectedId(selectedId === 6 ? null : 6)}
        >
          <div className="flex items-center gap-4">
            <div>{selectedId === 6 ? <ChevronDown /> : <ChevronRight />}</div>
            <h1 className="font-semibold">Könnt ihr euer System für uns anpassen?</h1>
          </div>
          {selectedId === 6 && (
            <ul className="text-gray-200 list-disc pl-4 mt-2">
              <li>Ja, wir bieten Anpassungsmöglichkeiten an, um sicherzustellen, dass unser System deinen Anforderungen entspricht.</li>
              <li>Kontaktieren uns <a href="#" onClick={handleOpenContactModal} className="text-blue-500 hover:underline">hier</a> für ein individuelles Angebot.</li>
            </ul>
          )}
        </div>
      </div>

      {/* Contact Modal Component */}
      <ContactModal open={isContactModalOpen} handleClose={handleCloseContactModal} />
    </div>
  );
}
