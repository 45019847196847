// src/app/store.ts

import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../slices/authSlice';
import eventsReducer from '../slices/eventsSlice'
import createEventReducer from '../slices/createEventSlice'
import createDrawerReducer from '../slices/drawerSlice'
import editEventReducer from '../slices/editEventSlice'
import clubEventsReducer from '../slices/clubEventsSlice'
import guestListReducer from '../slices/guestListSlice'
import promotersReducer from '../slices/promotersSlice'
import nearEventsReducer from '../slices/nearEventsSlice'
import clubsReducer from '../slices/clubsSlice'

const store = configureStore({
  reducer: {
    auth: authReducer,
    events: eventsReducer,
    event: createEventReducer,
    editEvent: editEventReducer,
    drawer: createDrawerReducer,
    clubEvents: clubEventsReducer,
    guestList: guestListReducer,
    promoters: promotersReducer,
    nearEvents: nearEventsReducer,
    clubs: clubsReducer
    // Add other reducers here if needed
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
