import { Box, CircularProgress } from "@mui/material";

export default function LoadingView() {
    return (
        <Box
            component="main"
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
                marginTop: 8,
                textAlign: "center",
                alignContent: "center",
            }}
        >
            <CircularProgress sx={{ color: "white" }} />
        </Box>
    )
}