import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store/store';

interface DrawerState {
    value: string;
}

const initialState: DrawerState = {
    value: 'ALL EVENTS', // Set initial value here
};

const drawerSlice = createSlice({
    name: 'currItem',
    initialState,
    reducers: {
        setCurrItem: (state, action: PayloadAction<string>) => {
            state.value = action.payload;
        },
    },
});

export const { setCurrItem } = drawerSlice.actions;

export const selectCurrItem = (state: RootState) => state.drawer.value;

export default drawerSlice.reducer;
