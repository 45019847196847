import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../redux/store/store';
import { parseDateFromString, parseNearEventDateFromString } from '../../../util/utils';
import LoadingView from '../../../components/general/LoadingView';
import EditEventForm from '../../../components/events/EditEventForm';
import { fetchNearEvents, selectNearEvents, selectNearEventsLoading } from '../../../redux/slices/nearEventsSlice';
import NearEventCard from '../../../components/events/NearEventCard';
import { EventData } from '../../../util/types';

function groupEventsByDate(events: EventData[]): { [key: string]: EventData[] } {
    return events.reduce((acc, event) => {
        const date = parseNearEventDateFromString(event.startTime);
        if (!acc[date]) {
            acc[date] = [];
        }
        acc[date].push(event);
        return acc;
    }, {} as { [key: string]: EventData[] });
}

export default function NearEventsListPage() {
    const dispatch: AppDispatch = useDispatch();
    const eventList = useSelector(selectNearEvents);
    const isLoading = useSelector(selectNearEventsLoading);
    const [selectedEventId, setSelectedEventId] = useState<string | null>(null);

    useEffect(() => {
        async function fetchData() {
            dispatch(fetchNearEvents());
        }
        fetchData();
    }, [dispatch]);

    if (isLoading) {
        return <LoadingView />;
    }

    const groupedEvents = groupEventsByDate(eventList || []);

    return (
        <Box
            component="main"
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
                marginTop: 8
            }}
        >
            {!selectedEventId ? (
                <>
                    <Typography fontSize={40} variant='h6' sx={{ marginLeft: 4 }}>
                        ALL EVENTS
                    </Typography>
                    {Object.keys(groupedEvents).map((date) => (
                        <div key={date}>
                            <Typography fontWeight={"bold"} color={"#61dafb"} variant='h5' sx={{ marginLeft: 4, marginTop: 2 }}>
                                {date}
                            </Typography>
                            <Grid container>
                                {groupedEvents[date].map((event: EventData) => (
                                    <Grid item xs={3} key={event.eventId}>
                                        <NearEventCard
                                            setEventSelected={(x) => setSelectedEventId(x)}
                                            eventDate={parseDateFromString(event.startTime)}
                                            eventImgUrl={event.eventImage ?? ""}
                                            eventName={event.eventName}
                                            eventId={event.eventId ?? ""}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </div>
                    ))}
                </>
            ) : (
                <EditEventForm eventId={selectedEventId} setSelectedEventId={setSelectedEventId} />
            )}
        </Box>
    );
}
