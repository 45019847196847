import { Alert, Grid, Paper } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { getAuth, signInWithEmailAndPassword, User } from 'firebase/auth';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import background_image from '../../assets/background_image.jpg';
import logo from '../../assets/logo.png';
import LoadingView from '../../components/general/LoadingView';
import { useFirebase } from '../../services/context/authContext';
import { errorFeedbacks } from '../../util/errors';
import { loginFailure, loginRequest, loginSuccess, selectAuthError, selectAuthLoading } from '../../redux/slices/authSlice';
import { useSelector, useDispatch } from 'react-redux';
import { validateLoginInputs } from '../../util/utils';

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" >
        Baila
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function SignIn() {
  const auth = getAuth();
  const dispatch = useDispatch();
  const isLoading = useSelector(selectAuthLoading);
  const errorStatus = useSelector(selectAuthError);
  const navigate = useNavigate();
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email')?.toString();
    const pw = data.get('password')?.toString();
    onLogin(email, pw)
  };

  const onLogin = async (email: string | undefined, password: string | undefined) => {
    dispatch(loginRequest());
    if (!validateLoginInputs(email, password)) {
      dispatch(loginFailure("auth/empty"));
      return;
    }

    try {
      // Perform Firebase login
      // @ts-ignore validated email above
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user as User | null;
      if (user) {
        dispatch(loginSuccess({
          email: user?.email ?? "",
          uid: user?.uid ?? "",
        }));
        navigate("/overview")
      } else {
        throw Error;
      }
    } catch (error: any) {
      dispatch(loginFailure(error?.code ?? ""));
    }
  }

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: `url(${background_image})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6}
        square>
        {!isLoading ? <Box
          sx={{
            my: 24,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <img src={logo} width={100} height={100} style={{ borderRadius: 20 }} alt="logo" />
          <Typography component="h1" variant="h5" mt={1} >
            Sign in
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            {errorStatus ? <Alert severity="error">{errorFeedbacks[errorStatus] ? errorFeedbacks[errorStatus] : "Fehler beim Einloggen"}</Alert> : null}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Copyright sx={{ mt: 5 }} />
          </Box>
        </Box> : <LoadingView />}
      </Grid>
    </Grid>
  );
}    