import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton, Toolbar, Typography } from "@mui/material";
import { getAuth, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { useFirebase } from '../../services/context/authContext';

export default function ShellToolbar({ open, setOpen }: { open: boolean, setOpen: (t: boolean) => void }) {
    const auth = getAuth();
    const navigate = useNavigate();
    const handleLogout = () => {
        signOut(auth).then(() => {
            // Sign-out successful.
            navigate("/login");
            console.log("Signed out successfully")
        }).catch((error) => {
            // An error happened.
        });
    }
    const toggleDrawer = () => {
        setOpen(!open);
    };
    return (
        <Toolbar
            sx={{
                pr: '24px', // keep right padding when drawer closed
            }}
        >
            <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer}
                sx={{
                    marginRight: '36px',
                    ...(open && { display: 'none' }),
                }}
            >
                <MenuIcon />
            </IconButton>
            <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                sx={{ flexGrow: 1 }}
            >
                Dashboard
            </Typography>
            <IconButton color="inherit">
                {/*             <Badge badgeContent={4} color="secondary">
              <NotificationsIcon />
            </Badge> */}
                {/* <NotificationsIcon /> */}
            </IconButton>
            <IconButton color="inherit" onClick={handleLogout}>
                <LogoutIcon />
            </IconButton>
        </Toolbar>
    )
}