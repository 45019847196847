import React from 'react';
import AppRouter from './shell/Router';

const App: React.FC = () => {
  return (
    <AppRouter />
  );
}

export default App;
