import { OwnerMessage } from "../util/types";
import { client } from "./api/client";
import { buildOwnersContactURL, buildUserOwnerDataURL } from "./api/urlBuilder";

export async function getOwnerUserData(userId: string, token: string) {
    const response = await client.get(buildUserOwnerDataURL({ userId: userId }), { token })
    return response.data
}

export async function sendOwnerContactMsg(email: string, name: string, msg: string, token: string) {
    const msgObj: OwnerMessage = {
        email: email,
        message: msg,
        name: name
    }
    const response = await client.post(buildOwnersContactURL(), msgObj, { token });
    return response.data
}