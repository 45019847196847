import { Box, Grid, Typography } from "@mui/material";
import { EventData } from "../../util/types";
import { parseDateFromString } from "../../util/utils";
import MediaCard from "./MediaCard";
import { selectClubEventList, selectClubEventsStatus } from "../../redux/slices/clubEventsSlice";
import { useSelector } from "react-redux";
import LoadingView from "../general/LoadingView";

interface GuestListEventListType {
    setEventSelected: (t: string) => void
}

export default function GuestListEventList({ setEventSelected }: GuestListEventListType) {
    const events = useSelector(selectClubEventList);
    const reqStatus = useSelector(selectClubEventsStatus);

    if (reqStatus === "pending") {
        return <LoadingView />
    }

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            marginTop={15}
        >
            <Box ml={4} sx={{ alignSelf: "flex-start" }}>
                <Typography fontSize={30} >Gästeliste</Typography>
            </Box>
            <Grid container >
                {events?.map(x => {
                    const newDate = parseDateFromString(x.startTime);
                    return (
                        <Grid item xs={3} key={x.eventId}>
                            < MediaCard
                                setEventSelected={setEventSelected}
                                eventDate={newDate}
                                key={x.eventId}
                                eventImgUrl={x.eventImage ?? ""}
                                eventName={x.eventName}
                                eventId={x.eventId ?? ""} /></Grid>)
                })}
            </Grid>
        </Box>
    )
}