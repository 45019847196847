import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { EventData, GuestListItem } from '../../util/types';
import { getClubEvents } from '../../services/events';
import { RootState } from '../store/store';
import { getGuestList, setCheckIn } from '../../services/tickets';
import { useFirebase } from '../../services/context/authContext';
import { getAuth } from 'firebase/auth';


// Define the initial state
interface GuestListState {
    guestList: GuestListItem[] | null;
    status: 'idle' | 'pending' | 'succeeded' | 'failed';
    error: string | null | undefined;
}

// Define initial state
const initialState: GuestListState = {
    guestList: null,
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
};

export const fetchGuestlist = createAsyncThunk('guestList/fetchGuestlist', async ({ eventId }: { eventId: string }) => {
    try {
        const auth = getAuth();
        const token = await auth.currentUser?.getIdToken();
        if (!token) {
            throw new Error("Failed to retrieve Token")
        }
        // get guestlist
        const res = await getGuestList(eventId, token);
        return res;
    } catch (error) {
        // Handle errors
        throw new Error('Failed to fetch GuestList');
    }
});

export const uploadCheckIn = createAsyncThunk('guestList/uploadCheckIn', async ({ eventId, ticketId, checkInStatus, extraCheckedIn }: { eventId: string, ticketId: string, checkInStatus: boolean, extraCheckedIn: number | null }) => {
    try {
        const auth = getAuth();
        const token = await auth.currentUser?.getIdToken();
        if (!token) {
            throw new Error("Failed to retrieve Token")
        }
        // set checkin
        await setCheckIn(ticketId, checkInStatus, extraCheckedIn, token);
        //refresh guestlist
        const res = await getGuestList(eventId, token);
        return res;
    } catch (error) {
        // Handle errors
        throw new Error('Failed to upload Check In status');
    }
});

// Create slice
const guestListSlice = createSlice({
    name: 'guestList',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchGuestlist.pending, (state) => {
                state.status = 'pending';
            })
            .addCase(fetchGuestlist.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.guestList = action.payload.guest_list;
            })
            .addCase(fetchGuestlist.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(uploadCheckIn.pending, (state) => {
                state.status = 'pending';
            })
            .addCase(uploadCheckIn.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.guestList = action.payload.guest_list;
            })
            .addCase(uploadCheckIn.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export default guestListSlice.reducer;

// Selectors
export const selectGuestList = (state: RootState) => state.guestList.guestList;
export const selectGuestListStatus = (state: RootState) => state.guestList.status;
export const selectGuestListError = (state: RootState) => state.guestList.error;
