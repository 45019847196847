import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { getClubEvents } from '../../../services/events';
import EventCard from '../../../components/events/EventCard';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEvents, selectEvents, selectEventsLoading } from '../../../redux/slices/eventsSlice';
import { AppDispatch } from '../../../redux/store/store';
import { parseDateFromString } from '../../../util/utils';
import LoadingView from '../../../components/general/LoadingView';
import { selectUser } from '../../../redux/slices/authSlice';
import EditEventForm from '../../../components/events/EditEventForm';

export default function EventsListPage() {
    const dispatch: AppDispatch = useDispatch();
    const eventList = useSelector(selectEvents);
    const isLoading = useSelector(selectEventsLoading);
    const user = useSelector(selectUser);
    const [selectedEventId, setSelectedEventId] = useState<string | null>(null);

    useEffect(() => {
        async function fetchData() {
            if (user?.clubId) {
                dispatch(fetchEvents({ clubId: user?.clubId }))
            }
        }
        fetchData();
    }, [])

    if (isLoading) {
        return <LoadingView />
    }


    return (
        <Box
            component="main"
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
                marginTop: 8
            }}
        >
            {!selectedEventId ?
                <>
                    <Typography fontSize={40} variant='h6' sx={{ marginLeft: 4 }} >ALL EVENTS</Typography>
                    <Grid container >
                        {eventList?.map((event, index) => (
                            <Grid item xs={3} key={event.eventId}>
                                <EventCard
                                    setEventSelected={(x) => setSelectedEventId(x)}
                                    key={event.eventId}
                                    clubId={user?.clubId ?? ""}
                                    event={event}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </>
                : <EditEventForm eventId={selectedEventId} setSelectedEventId={setSelectedEventId} />}

        </Box >
    )
}