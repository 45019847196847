// ThreeSteps.jsx
import { Check, DotIcon } from "lucide-react";
import { useState } from "react";
import ContactModal from "../../../general/ContactModal";

export default function ThreeSteps() {
  const [isContactModalOpen, setContactModalOpen] = useState(false);

  const handleOpenModal = () => setContactModalOpen(true);
  const handleCloseModal = () => setContactModalOpen(false);

  return (
    <div className="md:h-screen h-fit py-12 grid place-items-center mx-auto container">
      <div className="w-full flex flex-col items-center">
        <div className="w-[70%] hidden md:flex items-center">
          <DotIcon size={40} /> <div className="h-[4px] bg-white w-[44%]" />
          <DotIcon size={40} /> <div className="h-[4px] bg-white w-[44%]" />
          <Check className="ml-4" />
        </div>

        <div className="w-full md:w-[80%] flex flex-col md:flex-row items-start gap-4 text-lg font-semibold md:gap-0 md:text-base md:font-normal">
          <p className="text-left md:text-center font-bold">
            <span className="italic font-normal">Schritt&nbsp;1:</span>
            <div>Beantrage&nbsp;deinen&nbsp;Account</div>
            <button
              className="md:inline-flex items-center gap-2 bg-color3 text-black hover:bg-opacity-85 py-1 px-3 rounded-lg mx-auto mt-6"
              onClick={handleOpenModal} // Open modal on click
            >
              Jetzt Beantragen
            </button>
          </p>

          <div className="h-[4px] bg-black w-[55%] hidden md:block" />
          <p className="text-left md:text-center font-normal">
            <span className="italic">Schritt&nbsp;2:</span>
            <div>
              <span className="font-bold">Richte</span> dein{" "}
              <span className="font-bold">erstes Event</span> mit{" "}
              <span className="font-bold">Gästeliste</span> ein und lade{" "}
              <span className="font-bold">Promoter</span> ein, um die{" "}
              <span className="font-bold">Reichweite zu erhöhen</span>.
            </div>
          </p>

          <div className="h-[4px] bg-black w-[30%] hidden md:block" />
          <p className="text-left md:text-center font-normal">
            <span className="italic">Schritt&nbsp;3:</span>
            <div>
              <span className="font-normal">
                <span className="font-bold">Sammele</span> mit jedem{" "}
                <span className="font-bold">Event</span> mehr{" "}
                <span className="font-bold">Kundendaten</span> und{" "}
                <span className="font-bold">skaliere</span> deine Events mit{" "}
                <span className="font-bold">Analysen</span> und{" "}
                <span className="font-bold">Push-Nachrichten</span>.
              </span>
            </div>
          </p>
        </div>
      </div>

      {/* Contact Modal */}
      <ContactModal open={isContactModalOpen} handleClose={handleCloseModal} />
    </div>
  );
}
