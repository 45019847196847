import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';

interface EmailPopupProps {
    open: boolean;
    onClose: () => void;
}

const EmailPopup: React.FC<EmailPopupProps> = ({ open, onClose }) => {
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
            <DialogTitle>Einladen:</DialogTitle>
            <DialogContent>
                <Typography margin="normal" >Promoter können die "baila insights" App im Appstore herunterladen und deinen Club auswählen</Typography>
            </DialogContent>
            <DialogActions>
                {/* <Button onClick={onClose} color="primary">
                    Abbrechen
                </Button> */}
                <Button onClick={onClose} color="primary">
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EmailPopup;
