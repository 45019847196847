import { Box, Container, Grid, Paper, Toolbar, Typography } from "@mui/material";
import Blur from 'react-css-blur';
import Chart from "../../../components/dashboard/Chart";
import Deposits from "../../../components/dashboard/Deposits";
import Orders from "../../../components/dashboard/Orders";

export default function DashBoard() {
    return (
        <Box
            component="main"
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
            }}
        >
            <Toolbar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Typography
                    style={{ position: "absolute", left: "32%", top: "50%", bottom: "50%", zIndex: 1 }}
                    variant="h3"
                >
                    📈 Bald verfügbar
                </Typography>
                <Grid container spacing={3}>
                    {/* Chart */}
                    <Blur radius={'2px'}>
                        <Grid item xs={12} md={8} lg={9}>
                            <Paper
                                sx={{
                                    p: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: 240,
                                }}
                            >
                                <Chart />
                            </Paper>
                        </Grid>
                    </Blur>
                    {/* Recent Deposits */}
                    <Blur radius={'2px'}>
                        <Grid item xs={12} md={4} lg={3}>
                            <Paper
                                sx={{
                                    p: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: 240,
                                }}
                            >
                                <Deposits />
                            </Paper>
                        </Grid>
                    </Blur>
                    {/* Recent Orders */}
                    <Blur radius={'2px'}>
                        <Grid item xs={12}>
                            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                <Orders />
                            </Paper>
                        </Grid>
                    </Blur>
                </Grid>

            </Container>
        </Box>
    )
}