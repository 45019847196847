import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../redux/store/store';
import EventsListPage from './EventsListPage';
import { selectUser } from '../../../redux/slices/authSlice';
import MasterEventsListPage from './master/MasterEventsListPage';

export default function AllClubEventsPage() {
    const dispatch: AppDispatch = useDispatch();
    const user = useSelector(selectUser);


    if (user?.userType !== "MASTER") {
        return (
            <EventsListPage />
        )
    } else {
        return (
            <MasterEventsListPage />
        )
    }
}