// A tiny wrapper around fetch(), borrowed from
// https://kentcdodds.com/blog/replace-axios-with-a-simple-custom-fetch-wrapper


type RequestMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
interface ClientConfig extends RequestInit {
    headers?: { [key: string]: string };
    method?: RequestMethod;
    token?: string;
    body?: any;
}

export async function client(endpoint: string, { body, token, ...customConfig }: ClientConfig = {}) {
    const headers = {
        'Content-Type': 'application/json',
        // Add the Bearer Token if provided
        ...(token && { 'Authorization': `Bearer ${token}` }),
    };

    const config: RequestInit = {
        method: body ? 'POST' : 'GET',
        ...customConfig,
        headers: {
            ...headers,
            ...customConfig.headers,
        },
    };

    if (body) {
        config.body = JSON.stringify(body);
    }

    let data;
    try {
        const response = await fetch(endpoint, config);
        data = await response.json();
        if (response.ok) {
            // Return a result object similar to Axios
            return {
                status: response.status,
                data,
                headers: response.headers,
                url: response.url,
            };
        }
        throw new Error(response.statusText);
    } catch (err: any) {
        console.log("ERROR API REQUEST " + config?.method + " " + endpoint);
        return Promise.reject(err.message ? err.message : data);
    }
}

client.get = function (endpoint: string, customConfig = {}) {
    return client(endpoint, { ...customConfig, method: 'GET' });
};

client.post = function (endpoint: string, body: any, customConfig = {}) {
    return client(endpoint, { ...customConfig, body });
};
