import "../../App.css";
import Ambassador from "../../components/ownersPromo/LandingPage/Ambassador/Ambassador";
function AmbassadorPage() {
  return (
    <div className="grid gap-10 px-4 md:px-12 lg:px-24 mt-[74px] md:m-0">
      <Ambassador />
    </div>
  );
}

export default AmbassadorPage;
