import { FirebaseApp, initializeApp } from 'firebase/app';
import { Auth, getAuth } from 'firebase/auth';
import { ReactNode, createContext, useContext } from 'react';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDm3iCXmNq6s40WkEYfjSrx-qTfFfkh9C0",
    authDomain: "baila-740b8.firebaseapp.com",
    projectId: "baila-740b8",
    storageBucket: "baila-740b8.appspot.com",
    messagingSenderId: "318048668868",
    appId: "1:318048668868:web:dad7afabb11295feb50d91",
    measurementId: "G-KKW5N6W8SV"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

// Define the shape of your Firebase context data
type FirebaseContextData = {
    app: FirebaseApp;
    auth: Auth;
};

// Create a context instance
const FirebaseContext = createContext<FirebaseContextData | undefined>(undefined);

// Define a custom hook for using the Firebase context
export function useFirebase() {
    const context = useContext(FirebaseContext);
    if (!context) {
        throw new Error('useFirebase must be used within a FirebaseProvider');
    }
    return context;
}

// Define the context provider component
type FirebaseProviderProps = {
    children: ReactNode;
};

export function FirebaseProvider({ children }: FirebaseProviderProps) {
    const contextValue: FirebaseContextData = {
        app,
        auth,
    };

    return <FirebaseContext.Provider value={contextValue}>{children}</FirebaseContext.Provider>;
}
