import {
    setDefaults,
    fromAddress,
    OutputFormat,
    fromLatLng,
    geocode,
    RequestType,
} from "react-geocode";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { AdbRounded } from "@mui/icons-material";
import { resizeAndCompressImage } from "./utils";

setDefaults({
    key: "AIzaSyCFmh_01jwtnD2wOacyn9C5RUCpa6nTdEg", // Your API key here.
    language: "en", // Default language for responses.
    region: "de", // Default region for responses.
    outputFormat: OutputFormat.JSON
});


const STORAGE_BASE_PATH = "/home/baila/baila_owner/";

export async function getGeoCodeFromAdress(address: string) {
    let result = { lat: undefined, lng: undefined };
    try {
        const res = await fromAddress(address);
        if (res?.results) {
            const lat = res.results[0]?.geometry?.location?.lat;
            const lng = res.results[0]?.geometry?.location?.lng;
            result = { lat: lat, lng: lng };
        }
    }
    catch (err) {
        console.log(err)
    }
    return result;
}

export async function getAddressFromLatLng(lat: number, lng: number) {
    let res = null;
    // Get address from latitude & longitude.
    try {
        const resp = await geocode(RequestType.LATLNG, lat + "," + lng);
        const address = resp.results[0].formatted_address;
        res = address;
    } catch (error) {
        console.log(error)
    }
    return res;

}


export async function uploadToStorage(file: File, fileName: string): Promise<string | null> {
    if (file && fileName) {
        //compress image first
        const compressedFile = await resizeAndCompressImage(file);
        if (compressedFile) {
            const storage = getStorage();
            const storageRef = ref(storage, STORAGE_BASE_PATH + fileName);

            // Upload file to storage
            try {
                await uploadBytes(storageRef, compressedFile);

                // Get download URL for the uploaded file
                const downloadURL = await getDownloadURL(storageRef);
                return downloadURL;
            } catch (error) {
                console.error("Error uploading file:", error);
                return null;
            }
        } else {
            console.error("File couldnt be compressed.");
            return null;
        }
    } else {
        console.error("File or filename is missing.");
        return null;
    }
}