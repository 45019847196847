import '@fontsource/roboto/300.css';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
}

const drawerWidth = 240;
const navItems = ['Home', 'Datenschutz', 'Login'];

export default function PolicyPage(props: Props) {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const navigate = useNavigate();

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ my: 2 }}>
                Baila
            </Typography>
            <Divider />
            <List>
                <ListItem key={navItems[0]} disablePadding>
                    <ListItemButton sx={{ textAlign: 'center' }} onClick={() => navigate("/")} >
                        <ListItemText color='white' primary={navItems[0]} />
                    </ListItemButton>
                </ListItem>
                <ListItem key={navItems[1]} disablePadding>
                    <ListItemButton sx={{ textAlign: 'center' }} onClick={() => navigate("/policy")}>
                        <ListItemText color='white' primary={navItems[1]} />
                    </ListItemButton>
                </ListItem>
                <ListItem key={navItems[2]} disablePadding>
                    <ListItemButton sx={{ textAlign: 'center' }} onClick={() => { navigate("/login") }}>
                        <ListItemText color='white' primary={navItems[2]} />
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{
            display: 'flex',
            height: "100vh",
            backgroundColor: "#060809",
        }}>
            <CssBaseline />
            <Box component="main" sx={{ p: 3, backgroundColor: "#060809", }}>
                <Toolbar />
                <Typography variant='h5' color={"white"} fontWeight={"bold"} mb={2} >Datenschutzbestimmungen</Typography>
                <Typography color={"white"} >
                    1. Verantwortliche Stelle

                    Die verantwortliche Stelle für die Erhebung, Verarbeitung und Nutzung Ihrer personenbezogenen Daten im Sinne des Datenschutzrechts ist Kian Dousti, Leopoldstr. 206, 80804 München, kiandousti@yahoo.de.

                    2. Erhebung und Verarbeitung von personenbezogenen Daten

                    2.1. Bei der Nutzung unserer Event-App können folgende personenbezogene Daten erhoben und verarbeitet werden:

                    Benutzername
                    E-Mail-Adresse
                    Standortdaten

                    2.2. Die Erhebung und Verarbeitung dieser Daten erfolgt zum Zweck der Bereitstellung von Event-Informationen und Personalisierung von Benutzererlebnissen.

                    3. Nutzung und Weitergabe von personenbezogenen Daten

                    3.1. Wir nutzen Ihre personenbezogenen Daten ausschließlich für die in Abschnitt 2 genannten Zwecke.

                    3.2. Eine Weitergabe Ihrer Daten an Dritte erfolgt nur, sofern dies zur Erfüllung der in Abschnitt 2 genannten Zwecke erforderlich ist.

                    4. Datensicherheit

                    Wir setzen technische und organisatorische Maßnahmen ein, um Ihre Daten vor unbefugtem Zugriff, Verlust oder Zerstörung zu schützen. Unsere Sicherheitsmaßnahmen werden regelmäßig überprüft und dem technologischen Fortschritt angepasst.

                    5. Auskunftsrecht und Widerruf

                    Sie haben das Recht, Auskunft über die zu Ihrer Person gespeicherten Daten zu erhalten. Darüber hinaus können Sie Ihre Einwilligung zur Verarbeitung Ihrer Daten jederzeit widerrufen.

                    6. Kontakt

                    Bei Fragen zur Erhebung, Verarbeitung oder Nutzung Ihrer personenbezogenen Daten und für Auskünfte, Berichtigungen, Sperrung oder Löschung von Daten wenden Sie sich bitte an kiandousti@yahoo.de.
                </Typography>
                <Typography variant='h5' color={"white"} fontWeight={"bold"} mb={2} mt={2} >Impressum</Typography>
                <Typography color={"white"} >
                    Verantwortlich für den Inhalt:

                    Kian Dousti, Leopoldstr. 206, 80804 München, kiandousti@yahoo.de

                    Vertretungsberechtigte Person(en):

                    Kian Dousti

                    Haftungshinweis:

                    Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.

                    Urheberrecht:

                    Die Inhalte dieser Webseite unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechts bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.

                    Bildnachweise:

                    Die Bilder und Grafiken auf dieser Webseite stammen, soweit nicht anders angegeben, von Kian Dousti.

                    Datenschutz:

                    Informationen zum Datenschutz finden Sie in unserer Datenschutzerklärung.

                    Kontakt:

                    Bei Fragen oder Anregungen können Sie uns unter der oben angegebenen Adresse oder per E-Mail unter kiandousti@yahoo.de erreichen.
                </Typography>
            </Box>
        </Box >
    );
}
